import React from 'react';//, { useState, useEffect }
import AircraftIcon from '../icons/aircraftIcon';
import SvgIcon from '@material-ui/core/SvgIcon';

class DiaryAircraftStatusList extends React.Component {

  constructor(props) {
    super();
    this.props = props;
  };

  state = {
    aircraftList: [],
    onlineMinutes: 3,
    totalAircraft: 0,
  };

  componentDidMount() {
    this.update();
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.update();
    }
  };

  update = () => {
    const onlineMinutes = this.props.onlineMinutes || this.state.onlineMinutes;

    this.setState({
      aircraftList: this.props.aircraft,
      onlineMinutes: onlineMinutes,
      totalAircraft: this.props.aircraft.length,
    });
  };

  render() {
    const { res } = this.props;

    return (
      <div className='dashboard-card' style={{ minWidth: '10rem', minHeight: '10rem', display: 'flex', flexDirection: 'column' }}>
        <div className='divContent' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <div className='cardheader'>
            <SvgIcon viewBox="0 0 800 800" style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }}>
              <path d="M 0 156.81 L 0 0 L 65.28 0 L 125.41 103.72 L 243.59 87.54 C 260.71 67.18 282.02 49.29 307.72 33.88 C 333.41 18.46 354.72 10.66 371.85 10.66 L 381.93 10.66 C 395.25 10.28 409.14 19.04 423.8 36.54 C 438.26 54.24 448.73 67.56 455.58 76.51 L 734.36 95.92 L 734.36 21.32 L 753.2 21.32 L 753.2 116.66 C 757.39 116.66 762.34 117.61 768.62 119.7 C 774.71 121.8 777.75 123.89 777.75 126.17 C 777.75 128.65 774.71 132.07 768.62 136.45 C 762.34 140.64 757.58 142.73 753.77 142.73 L 753.77 242.06 L 734.74 242.06 L 734.74 168.99 L 569.18 208 L 569.18 251.01 C 583.46 256.15 590.5 266.23 590.5 281.08 C 590.5 302.58 579.84 313.43 558.72 313.43 C 537.02 313.43 526.37 302.58 526.37 281.08 C 526.37 265.47 533.98 255.19 549.2 250.44 L 549.2 210.29 L 501.82 225.89 L 384.78 225.89 L 0 156.81 Z M 345.58 76.51 L 418.66 76.51 L 382.5 39.21 L 345.58 39.21 L 345.58 76.51 Z"></path>
            </SvgIcon>
            <span className='title'>
              {res.AERONAVES_}
            </span>
          </div>
          <div className='cardmiddle' style={{ flexGrow: 1, overflowY: 'auto', maxHeight: 'calc(100% - 5rem)' }}>
            <div style={{ display: 'block', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0', color: 'white', fontWeight: 'bold' }}>
                <span style={{ width: '70%' }}></span>
                <span style={{ width: '30%', textAlign: 'center' }}>
                  {res.HECTARES_PULVERIZADOS}
                </span>
              </div>
              {this.state.aircraftList.map((aircraft, i) => {
                const isOnline = aircraft.lastheartbeat ? new Date() - new Date(aircraft.lastheartbeat) < (this.state.onlineMinutes * 60000) : false;
                const onlineColor = isOnline ? '#32CD32' : '#D3D3D3';
                const hectares = aircraft.hsprayed > 0 ? aircraft.hsprayed + 'ha' : 'N/A';
                const hectaresColor = aircraft.hsprayed > 0 ? '#32CD32' : '#D3D3D3';

                return (
                  <span key={i} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '.5rem' }}>
                    <span style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                      <span style={{ width: '1rem', height: '1rem', borderRadius: '1rem', backgroundColor: onlineColor }}></span>
                      <span style={{ width: '2.5rem', height: '2.5rem', padding: '.2rem', backgroundColor: '#FFF', borderRadius: '.4rem', marginLeft: '.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {aircraft.svg && <AircraftIcon aircraft={aircraft} width={30} height={30} />}
                      </span>
                      <span style={{ paddingLeft: '.5rem', color: 'white', fontWeight: 'bold' }}>{aircraft.name}</span>
                    </span>
                    <span style={{ width: '30%', textAlign: 'center', color: hectaresColor, fontWeight: 'bold', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                      {hectares}
                    </span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className='cardfooter'>
            <span className='cardfooter-content' style={{ paddingTop: '.7rem' }}>
              <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                  {res.TOTAL_AERONAVES}
                </span>
              </span>
              <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%', fontSize: '1rem', fontWeight: 'bold' }}>
                <span style={{ display: 'table-row', width: '100%' }}>
                  {this.state.totalAircraft}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export default DiaryAircraftStatusList;