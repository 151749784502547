import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import Skeleton from '@material-ui/lab/Skeleton';
import EqualizerIcon from '@material-ui/icons/Equalizer';

class DiaryApplication extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            jobs: props.jobs || [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            this.update();
        }
    }

    update = () => {
        this.setState({
            jobs: this.props.jobs || [],
            isLoading: false,
        }, this.renderChart);
    };

    renderChart = () => {
        const { jobs } = this.state;
        const { res } = this.props;

        const chartElement = this.chartRef.current;
        if (chartElement) {
            const ctx = chartElement.getContext('2d');

            const labels = jobs.map(job => job.title || 'Sem Título');
            const plannedData = jobs.map(job => job.hplanned || 0);
            const executedData = jobs.map(job => job.hsprayed || 0);

            const plannedDataset = {
                label: 'Área Planejada (ha)',
                data: plannedData,
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1,
            };

            const executedDataset = {
                label: 'Área Executada (ha)',
                data: executedData,
                backgroundColor: '#FFE7D0',
                borderColor: '#FFE7D0',
                borderWidth: 1,
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            this.barChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels,
                    datasets: [plannedDataset, executedDataset],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Planejado / Executado',
                            color: 'white',
                            position: 'bottom',
                        },
                        legend: {
                            labels: {
                                color: 'white',
                            },
                        },
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: 'lightgray',
                                beginAtZero: true,
                            },
                            title: {
                                display: true,
                                color: 'white',
                                text: 'Área (ha)',
                            },
                        },
                        x: {
                            ticks: {
                                color: 'lightgray',
                                maxRotation: 0,
                                minRotation: 0,
                                autoSkip: true,
                                maxTicksLimit: 6,
                            }
                        }
                    },
                },
            });
        }
    };

    render() {
        const { res } = this.props;
        const { isLoading, jobs } = this.state;

        return (
            <div className="dashboard-card" style={{ height: '100%' }}>
                <div className="divContent" style={{ height: '100%' }}>
                    <div className="cardheader">
                        <EqualizerIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className="title">{res.APLICACOES}</span>
                    </div>
                    <div className="cardmiddle" style={{ height: 'calc(100% - 2rem)' }}>
                        {isLoading || jobs.length === 0 ? (
                            <Skeleton variant="rect" width="100%" height="100%" />
                        ) : (
                            <div className="chartContainer" style={{ width: '100%', height: '100%' }}>
                                <canvas ref={this.chartRef} style={{ width: '100%', height: '100%' }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DiaryApplication.propTypes = {
    jobs: PropTypes.array.isRequired,
    res: PropTypes.object.isRequired,
};

export default DiaryApplication;