import React from 'react';

export default (props) => {
    const { style, onClick, open } = props;

    const s = {
        backgroundColor: style.color, 
        zIndex: style.zIndex,
        display: open ? 'block' : 'none'
    };

    return (
        <div className="back-drop" style={s} onClick={onClick}>
            <div className="in-back-drop">
                {props.children}
            </div>
        </div>
    )
}
