import React from 'react';
import Config from '../../constants/appConfig';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, Tooltip, Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, IconButton, FormControl, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'moment';
import thermalInversionExample from '../../assets/images/thermalInversionExample.png';
import normalFlowExample from '../../assets/images/normalFlowExample.png';
import DeltaTHeat from '../../components/heat_graph/deltaTGraph';

const styles = theme => ({
    select: {
        color: 'black',
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: 'black',
        },
    },
    menuPaper: {
        backgroundColor: '#343a40',
    },
    customWidthTooltip: {
        maxWidth: 'none',
    },
    dialogTitle: {
        backgroundColor: '#2D3238',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 1.5rem',
        position: 'relative',
    },
    dialogContent: {
        backgroundColor: '#FFF',
        padding: '1.5rem',
    },
    dialogActions: {
        padding: '1rem',
        justifyContent: 'flex-end',
        backgroundColor: '#FFF',
    },
    closeButton: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'white',
    },
});

class DiaryClimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            temperature: '',
            air_temperature: '',
            air_humidity: '',
            humidity: '',
            windSpeed: '',
            deltaT: '',
            windDirection: '',
            thermalInversion: '',
            pressure: '',
            datetime: '',
            loading: true,
            stations: [],
            selectedStation: '',
            date: Moment().format('YYYY-MM-DD'),
            openDialog: false,
            intervalId: null,
        };
    }

    componentDidMount() {
        this.listStations().then(() => {
            const { stations } = this.state;
            if (stations.length > 0) {
                this.setState({ selectedStation: stations[0].id }, () => {
                    this.getDataById(stations[0].id);
                });
            }
        });

        const intervalId = setInterval(() => {
            const { selectedStation } = this.state;
            if (selectedStation) {
                this.getDataById(selectedStation);
            }
        }, 300000);

        this.setState({ intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    listStations = () => {
        const apiUrl = `${Config.server}/weather_source`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        return fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then(
                (result) => {
                    this.setState({
                        stations: result,
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
    };

    getDataById = (sourceID) => {
        const apiUrl = `${Config.server}/weather_data/${sourceID}/${this.state.date}`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access-token-jwt')}`,
                'Content-Type': 'application/json',
            },
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                if (result.length > 0) {
                    const element = result[result.length - 1];
                    this.setState({
                        temperature: element.air_temperature ? `${element.air_temperature} °C` : '-',
                        air_temperature: element.air_temperature ? `${element.air_temperature}` : '-',
                        humidity: element.air_humidity ? `${element.air_humidity} %` : '-',
                        air_humidity: element.air_humidity ? `${element.air_humidity}` : '-',
                        windSpeed: element.wind_speed ? `${element.wind_speed} km/h` : '-',
                        deltaT: element.delta_t ? `${element.delta_t}` : '-',
                        windDirection: element.wind_direction ? `${element.wind_direction} °` : '-',
                        thermalInversion: element.air_temperature - 5 > element.temperature ? 'Grandes chances de estar ocorrendo inversão térmica' :
                            element.air_temperature - 1 > element.temperature ? 'Pode estar ocorrendo inversão térmica' : 'Não está ocorrendo inversão térmica',
                        pressure: element.pressure ? `${element.pressure} hPa` : '-',
                        datetime: element.datetime ? Moment(element.datetime).format("HH:mm  DD/MM/YYYY") : '-',
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
            });
    };

    handleStationChange = (event) => {
        const selectedStation = event.target.value;
        this.setState({ selectedStation, loading: true, openDialog: false }, () => {
            this.getDataById(selectedStation);
        });
    };

    handleClickOpenDialog = () => {
        this.setState({ openDialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    };

    render() {
        const { classes, res } = this.props;
        const { temperature, humidity, windSpeed, deltaT, windDirection, thermalInversion, pressure, datetime, loading, stations, selectedStation, openDialog } = this.state;

        const climateData = [
            { label: 'Temperatura', value: temperature },
            { label: 'Umidade', value: humidity },
            { label: 'Vel. do Vento', value: windSpeed },
            { label: 'Delta-T', value: deltaT },
            { label: 'Dir. do Vento', value: windDirection },
            { label: 'Pressão', value: pressure },
            { label: 'Inversão Térmica', value: thermalInversion },
        ];

        return (
            <div className='dashboard-card' style={{ minWidth: '10rem', minHeight: '10rem' }}>
                <div className='divContent'>
                    <div className='cardheader' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FilterDramaIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                            <span className='title' style={{ marginLeft: '0.5rem', color: 'white' }}>
                                {res.CLIMA_}
                            </span>
                        </div>
                        <Button variant="outlined" style={{ color: '#fa7d34', border: '1px solid #fa7d34' }} onClick={this.handleClickOpenDialog}>
                            {stations.find(station => station.id === selectedStation)?.name || "Selecionar Estação"}
                        </Button>
                    </div>
                    <div className='cardmiddle'>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0.5rem', width: '100%' }}>
                            {climateData.map((data, index) => {
                                if (data.label === 'Inversão Térmica') {
                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '25.25rem'
                                                }}
                                            >
                                                {data.value === "Não está ocorrendo inversão térmica"
                                                    ?
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={normalFlowExample} alt="Fluxo normal" />
                                                    :
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={thermalInversionExample} alt="Fluxo com inversão térmica" />
                                                }
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ gridColumn: 'span 3', borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: 'white', fontWeight: 'bold' }}>
                                                    {loading ? <Skeleton width="12rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                if (data.label === 'Delta-T') {
                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '22.5rem'
                                                }}
                                            >
                                                <DeltaTHeat
                                                    atual={deltaT}
                                                    humidade={this.state.air_humidity}
                                                    temperatura={this.state.air_temperature}
                                                />
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: 'white', fontWeight: 'bold' }}>
                                                    {loading ? <Skeleton width="4rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                return (
                                    <div key={index} style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                        <span style={{ display: 'block', color: '#fa7d34' }}>
                                            {data.label}
                                        </span>
                                        <span style={{ display: 'flex', justifyContent: 'center', color: 'white', fontWeight: 'bold' }}>
                                            {loading ? <Skeleton width="4rem" /> : data.value}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='cardfooter'>
                        <span className='cardfooter-content' style={{ paddingTop: '.7rem' }}>
                            <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                                    {res.ATUALIZADO}
                                </span>
                            </span>
                            <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%', fontSize: '1rem', fontWeight: 'bold', color: 'white' }}>
                                <span style={{ display: 'table-row', width: '100%' }}>
                                    {loading ? <Skeleton width="10rem" /> : datetime}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>

                <Dialog open={openDialog} onClose={this.handleCloseDialog} fullWidth maxWidth="sm" disablePortal>
                    <DialogTitle className={classes.dialogTitle}>
                        <Typography variant="h6" style={{ color: 'white' }}>
                            {res.SELECIONAR_ESTACAO}
                        </Typography>
                        <IconButton aria-label="close" onClick={this.handleCloseDialog} className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <FormControl fullWidth>
                            <Select
                                labelId="station-select-label"
                                value={selectedStation}
                                onChange={this.handleStationChange}
                                className={classes.select}
                            >
                                {stations.map((station) => (
                                    <MenuItem key={station.id} value={station.id}>
                                        {station.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DiaryClimate);