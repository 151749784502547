import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';
import TextField from '@material-ui/core/TextField';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";

import L from 'leaflet';
import 'leaflet-draw';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import tools from '../../components/util/tools';
import Slider from '../../components/Slider/slider';

import { useParams } from "react-router";

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Arrow from './arrow';
import saveAs from 'file-saver';

import Img_mark from '../../components/image/img';
import img from '../point/img';

import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class marks {
  constructor() {
    this.id = '';
    this.guid = '';
    this.name = '';
    this.icon = '';
    this.alt = '';
    this.lat = '';
    this.lng = '';
    this.r = '';
    this.type = '';
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  table: {
    minWidth: 650,
  },
});

class Edit extends React.Component {
  base_state = {
    markers: [],

    title: '',

    ruler_a: false,
    stack: false,
    stack_value: [1, 1, 1],
    progress: true,
    tab: '0',

    base: [],

    itensTask: [],
    task: 0,
    id: 0,
    guid: ""
  };
  state = JSON.parse(JSON.stringify(this.base_state));

  tabItens = [
    { label: this.props.res.INFORMACAO, value: '0' },
    { label: 'asdasdasd', value: '1' },
  ];

  dificult = [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
  ];

  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  dataEstJob = null;
  dataFullJob = null;

  featureGroupL = null;
  featureGroupP = null;
  featureGroupPoly = null;
  featureGroupPM = null;

  area = 0;

  componentDidMount() {
    this.icon = Img_mark;
    this.boot();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  boot = () => {
    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupL = L.featureGroup();
    this.featureGroupL.addTo(this.mymap);
    this.featureGroupP = L.featureGroup();
    this.featureGroupP.addTo(this.mymap);
    this.featureGroupPoly = L.featureGroup();
    this.featureGroupPoly.addTo(this.mymap);

    this.featureGroupPM = L.featureGroup();
    this.featureGroupPM.addTo(this.mymap);

    new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);

    this.mymap.on('click', () => {
      this.handleClickAplMap();
    });

    this.mymap.on('draw:drawvertex', e => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

    this.loadTask(false);
    if (!this.props.guid) {
      this.props.link('/app/relatorio');
    } else {
      this.checkJob();
    }

    this.mymap.on('moveend', (e) => {
      this.checkMarkers();
    });

    this.mymap.on('zoomend', () => {
      this.checkMarkers();
    });

    this.list_markers();

  };

  checkJob = () => {
    let guid = this.props.guid;
    let apiUrl = Config.server + '/job_check/importzjob/' + guid;
    let method = 'GET';

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          if (result.data && result.data.task) {
            this.checkTask(result.data.task, false);
          } else if (result.task && result.task.id) {
            this.checkTask(result.task.id, false);
          }

          if (result.task && result.task.id) {
            this.setState({ task: result.task.id });
          }

          if (result.id_job) {
            this.setState({ id_job: result.id_job });
          }
          if (result.data && result.data.importzjob) {
            this.useData(result.data.importzjob);
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  list_markers = () => {
    const apiUrl = Config.server + '/point/0';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let m = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];

              let tempMark = new marks();
              tempMark.id = element.id;
              tempMark.guid = element.guid;
              tempMark.name = element.name;
              tempMark.lat = element.lat;
              tempMark.lng = element.lng;
              tempMark.alt = element.height;
              tempMark.r = element.radios;
              tempMark.desc = element.icon;
              tempMark.type = element.type;
              for (let j = 0; j < img.length; j++) {
                if (img[j].desc.includes(element.icon)) {
                  tempMark.img = img[j].img;
                  break;
                }
              }
              m.push(tempMark)
              this.drawMarker(tempMark);
            }
            this.setState({ markers: m });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  drawMarker = (marker) => {
    let dIcon = L.icon({
      iconUrl: marker.img,
      iconAnchor: marker.type === 'O' ? [25, 30] : [13, 33],
      popupAnchor: [0, -25]
    });
    let newLatLng = new L.LatLng(marker.lat, marker.lng);
    let myLayer = L.marker(newLatLng, { icon: dIcon });
    myLayer._leaflet_id = marker.guid;
    myLayer.bindPopup(marker.name);
    myLayer.addTo(this.featureGroupPM);

    if (marker.r > 0) {
      let myStyle = {
        "color": 'red',
        "weight": 4,
        "opacity": 0.5,
        "radius": marker.r
      };
      myLayer = L.circle(newLatLng, myStyle);
      myLayer._leaflet_id = marker.guid + 'r';
      myLayer.addTo(this.featureGroupPM);
    }
  }

  checkMarkers = () => {
    let mapBounds = this.mymap.getBounds();
    for (let i = this.state.markers.length - 1; i >= 0; i--) {
      let m = this.mymap._layers[this.state.markers[i].guid];
      if (this.mymap.getZoom() > 11) {
        if (!m) {
          this.drawMarker(this.state.markers[i]);
        } else {
          let shouldBeVisible = mapBounds.contains(m.getLatLng());
          if (m._icon && !shouldBeVisible) {
            this.featureGroupPM.removeLayer(m);
            let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
            if (mr)
              this.featureGroupP.removeLayer(mr);
          }
        }
      } else if (m) {
        this.featureGroupPM.removeLayer(m);
        let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
        if (mr)
          this.featureGroupP.removeLayer(mr);
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeTab = name => (event, newValue) => {
    this.setState({
      [name]: newValue ? newValue : event.target.value,
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatP')) {
      dF.classList.toggle("div-listFloatP");
    } else {
      dF.classList.remove("div-listFloatP");
    }
  };

  handleStackClick = (tipo) => {
    let stack_value = this.state.stack_value;
    if (tipo === 1) {
      if (stack_value[0] === 1) {
        stack_value[0] = 0;
        tools.cssShow('aplicacao', false);
      } else {
        stack_value[0] = 1;
        tools.cssShow('aplicacao', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 2) {
      if (stack_value[1] === 1) {
        stack_value[1] = 0;
        tools.cssShow('caminho', false);
      } else {
        stack_value[1] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 3) {
      if (stack_value[2] === 1) {
        stack_value[2] = 0;
        tools.cssShow('area', false);
      } else {
        stack_value[2] = 1;
        tools.cssShow('area', true);
      }
      this.setState({ stack_value: stack_value });
    }
  };

  markMap = (type, pos) => {
    let label = 'mark';
    if (type === 11) {
      if (this.state.base.length > 0) {
        this.featureGroupPoly.removeLayer(this.mymap._layers['base']);
      }
      this.setState({ base: pos });
      label = 'base';
    }
    let dIcon = L.icon({
      iconUrl: this.icon[type].img,
      iconAnchor: [13, 33]
    });
    let mark = L.marker(pos, { icon: dIcon });
    if (label === 'base') {
      mark._leaflet_id = label;
    }
    this.featureGroupPoly.addLayer(mark);
    this.mymap.fitBounds(this.featureGroupPoly.getBounds());
  };

  polyMap = (poly) => {
    const color_x = '#ff002b';
    let myStyle = {
      color: '#47839e',
      weight: 4,
      opacity: 0.5,
      fillColor: '#FF1493',
      fillOpacity: .7,
      className: 'area'
    };

    if (poly[1] === 'X') {
      myStyle.color = color_x;
      myStyle.fillColor = color_x;
    }

    // GeoJSON parse
    const geojson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    };

    const coords = JSON.parse(poly[2]);

    for (let c = 0; c < coords.length; c++) {
      const lng = coords[c][0];
      const lat = coords[c][1];
      if (!isNaN(lat) && !isNaN(lng)) {
        geojson.geometry.coordinates[0].push([lat, lng]);
      }
    }

    let myLayer = L.geoJSON(geojson, { style: myStyle });
    myLayer.addTo(this.featureGroupPoly);
  };

  // abMap = (poly) => {
  //   if (poly.abData && poly.abData.line) {
  //     const myStyle = {
  //       "color": '#f5c749',
  //       "weight": 2,
  //       "opacity": 1
  //     };
  //     let myLayer = L.geoJSON(poly.abData.line, { style: myStyle });
  //     myLayer.addTo(this.featureGroupPoly);


  //     const latlngs = poly.abData.line.geometry.coordinates;
  //     let imgIcon = Img_mark[8].img;

  //     let dIcon = L.icon({
  //       iconUrl: imgIcon,
  //       iconAnchor: [9, 30]
  //     });
  //     let newLatLng = new L.LatLng(latlngs[0][1], latlngs[0][0]);
  //     myLayer = L.marker(newLatLng, { icon: dIcon });
  //     myLayer.addTo(this.featureGroupPoly);

  //     const bearing = poly.abData.bearing < 0 ? 360 + poly.abData.bearing : poly.abData.bearing;
  //     const pop = this.props.res.ANGULO + ": " + bearing.toFixed() + 'º<br> ' + this.props.res.ESTRATEGIA + ': ' + poly.abData.strategy + '<br> ' + this.props.res.LARGURA_FAIXA + ': ' + poly.abData.width + '<br> ' + this.props.res.FLUXO_APLICACAO + ': ' + poly.abData.setPoint;

  //     imgIcon = Img_mark[9].img;

  //     dIcon = L.icon({
  //       iconUrl: imgIcon,
  //       iconAnchor: [9, 30],
  //       popupAnchor: [9, -30]
  //     });
  //     newLatLng = new L.LatLng(latlngs[1][1], latlngs[1][0]);
  //     myLayer = L.marker(newLatLng, { icon: dIcon });
  //     myLayer.bindPopup(pop);
  //     myLayer.addTo(this.featureGroupPoly);

  //     imgIcon = Img_mark[22].img;

  //     dIcon = L.icon({
  //       iconUrl: imgIcon,
  //       iconAnchor: [-13, 35],
  //       popupAnchor: [9, -30]
  //     });
  //     dIcon.options.iconSize = [16, 16];
  //     newLatLng = new L.LatLng(latlngs[1][1], latlngs[1][0]);
  //     myLayer = L.marker(newLatLng, { icon: dIcon });
  //     myLayer.bindPopup(pop);
  //     myLayer.addTo(this.featureGroupPoly);

  //   }
  // };

  aplMapCorrect = (poly) => {
    let myStyle = {
      color: '#32a852',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao'
    };

    const pop =
      '<b>' + this.props.res.TIRO + ': ' + poly.info.title + '</b><br> '
      + this.props.res.LARGURA_FAIXA + ': ' + poly.info.faixa + ' m<br> '
      + this.props.res.VELOCIDADE_MEDIA + ': ' + poly.info.speed.toFixed(2) + 'km/h<br> '
      + this.props.res.AREA + ': ' + poly.info.ha.toFixed(3) + ' ha<br> '
      + this.props.res.VOLUME + ': ' + poly.info.l.toFixed(3) + ' L<br> '
      + this.props.res.FLUXO_MEDIO + ': ' + poly.info.lha.toFixed(3) + ' L/ha<br>'
      + 'Altura média: ' + poly.info.alt + 'm';

    // GeoJSON parse
    const geojson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    };

    const coords = poly.segmento.split(' ');

    for (let c = 0; c < coords.length; c++) {
      const coord = coords[c].split(',');
      const lat = parseFloat(coord[0]);
      const lng = parseFloat(coord[1]);
      if (!isNaN(lat) && !isNaN(lng)) {
        geojson.geometry.coordinates[0].push([lat, lng]);
      }
    }

    let myLayer = L.geoJSON(geojson, { style: myStyle });
    myLayer.on("click", () => { this.handleClickAplMap(myLayer); });
    myLayer.bindPopup(pop);
    myLayer.addTo(this.featureGroupP);
  };

  handleClickAplMap = (layer) => {
    this.featureGroupP.eachLayer((item) => {
      item.setStyle({ color: '#32a852' });
    });
    if (layer) {
      layer.setStyle({ color: '#25c450' });
    }
  };

  pathMap = (line) => {
    let myStyle = {
      color: '#d0d0d0',
      weight: 1,
      opacity: 1,
      smoothFactor: 1,
      className: 'caminho'
    };

    let myLayer = L.geoJSON(line, { style: myStyle });
    myLayer.addTo(this.featureGroupL);
  };

  aplMap = (poly) => {
    let myStyle = {
      color: "#ffd12b",
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao'
    };

    let myLayer = L.geoJSON(poly, { style: myStyle });
    myLayer.addTo(this.featureGroupP);
  };

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupPoly.removeLayer(this.mymap._layers['regua']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM2']);
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      if (this.drawCreateToolbar) {
        this.drawCreateToolbar.disable();
      }
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  };

  completeRuler = () => {
    if (this.drawCreateToolbar) {
      this.drawCreateToolbar.disable();
    }
    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  };

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  };
  keyCell = 0;
  item_title = (res) => {
    return (<TableRow key={this.keyCell++}>
      <TableCell component="th" scope="row" colSpan={4} style={{ borderBottom: 0 }}>
        <div className="titleTextRel">
          {res}
        </div>
      </TableCell>
    </TableRow>);
  };

  item_value = (exe, exe_valor, total_valor) => {
    const per_exe = exe_valor ? this.timePercentual(total_valor, exe_valor) : false;
    const exe_temp = per_exe && '';
    return (<TableRow key={this.keyCell++}>
      <TableCell component="th" scope="row"></TableCell>
      <TableCell align="right" style={{ position: 'relative' }}><span>{exe}</span>{exe_temp}</TableCell>
    </TableRow>);
  };

  checkTask = (item, pro) => {
    const temp_pro = pro;
    this.setState({ openTask: false, progress: pro });
    let apiUrl = Config.server + '/getDetailedResult/' + item;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result === 'file not found.') {
            this.loadTask(true);
          } else {
            localStorage.setItem('report_check-task', item);
            this.setState({ task: item });
            if (temp_pro) {
              this.setState({ valor: 0, time: 0 }, () => { this.useData(JSON.parse(result)) });;
            } else {
              this.useData(JSON.parse(result));
            }
            // setTimeout(() => {
            //   this.addKm();
            // }, 40);
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  useData = (result) => {
    if (result && result.znavWithAlt) {
      //if (result.linha) {
      this.featureGroupL.eachLayer((layer) => {
        this.featureGroupL.removeLayer(layer);
      });

      this.featureGroupPoly.eachLayer((layer) => {
        this.featureGroupPoly.removeLayer(layer);
      });

      this.featureGroupP.eachLayer((layer) => {
        this.featureGroupP.removeLayer(layer);
      });

      const segmentosAplicados = [];

      for (let i = 0; i < result.shapes.rows.length; i++) {
        const element = result.shapes.rows[i];
        this.polyMap(element);

        const tiros = result.shapes.rows[i][10];

        if (tiros) {
          for (let t = 0; t < tiros.length; t++) {
            const segmentos = tiros[t].tiro;

            for (let s = 0; s < segmentos.length; s++) {
              const segmento = segmentos[s];
              segmento.info.title = `Tiro ${t} / Segmento ${s}`;
              segmentosAplicados.push(segmento);
            }
          }
        }
      }

      for (let i = 0; i < segmentosAplicados.length; i++) {
        this.aplMapCorrect(segmentosAplicados[i]);
      }

      // GeoJSON parse
      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: []
        }
      };

      for (let z = 0; z < result.znavWithAlt.length; z++) {
        const line = result.znavWithAlt[z];
        const coords = line.split(' ');

        for (let c = 0; c < coords.length; c++) {
          const coord = coords[c].split(',');
          const lat = parseFloat(coord[0]);
          const lng = parseFloat(coord[1]);
          if (!isNaN(lat) && !isNaN(lng)) {
            geojson.geometry.coordinates.push([lat, lng]);
          }
        }
      }

      this.pathMap(geojson);

      if (this.state.base.length > 0) {
        this.markMap(11, this.state.base);
      }

      if (result.shapes.rows.length) {
        this.mymap.fitBounds(this.featureGroupP.getBounds());
      }

      this.setState({
        id: result.id,
        guid: result.guid,
        title: result.title,
        progress: false
      }, () => { /* localStorage.setItem('c_report_map', JSON.stringify(this.state)); */ });
    } else {
      if (!result) {
        this.setState({ progress: false });
        notification.open({
          message: this.props.res.PROCESSANDO,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
      }
    }
  };

  loadTask = (open) => {
    let apiUrl = Config.server + '/task/report'

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            setTimeout(() => {
              if (this.state.openTask) {
                this.loadTask(true);
              }
            }, 3000);
            let itens = [];
            for (let i = 0; i < result.length; i++) {
              itens.push({ id: result[i].id, title: result[i].title, progress: result[i].progress });
            }
            this.setState({
              itensTask: itens,
              openTask: open
            });
          } else {
            this.setState({ itensTask: [] });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  render() {
    const { res } = this.props;

    return (
      <div className="gridEst maxH">
        <div className="divContent">
          <div className="containerTitle">
            <div><h1>KMZ {this.state.title}</h1></div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" className="popUpButton" color="primary" style={{ marginRight: '.4rem' }} onClick={event => (this.props.link('/app/relatorio/' + this.props.guid))} title={'Voltar'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="-4 -4 24 24">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                </svg>
              </Button>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
            <CircularProgress color="inherit" disableShrink />
          </Backd>
          <div className="maxVH">
            <div className="divContent divRelative map-p">
              <div className="containerTop">
                <div className="leftTop">
                  <Button className="buttonS buttonMenu buttonMenuSmall" onClick={event => this.showMenu()}>
                    <MaterialIcon icon="menu" className="iconMapF" />
                  </Button>
                  <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.regua()}>
                    <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
                  </Button>
                </div>
                <div className="centerTop"></div>
                <div className="rightTop">
                  <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                    <Button className="buttonS buttonMenu" onClick={event => this.setState({ stack: !this.state.stack })}>
                      <MaterialIcon icon="layers" className="iconMap" />
                    </Button>
                  </label>
                </div>
              </div>

              <div className="wrapperMap">
                <div id="mapid"></div>
                <div className="zoomB">
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                    <MaterialIcon icon="add" className="iconMap" />
                  </Button>
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                    <MaterialIcon icon="remove" className="iconMap" />
                  </Button>
                </div>

                {/* <div className={this.state.addKm ? "divContent floatDiv floatDivItens popUpF" : "sr-only"}>
                  <div style={{ overflowY: 'auto' }}>
                    <div>
                      <TextField
                        id="list_name"
                        label={res.ADD_TRANSLADO}
                        margin="none"
                        className="textI"
                        size="small"
                        style={{ width: '12rem' }}
                        value={this.state.valor}
                        onChange={this.handleChange("valor")}
                        onKeyDown={e => {
                          if (e.keyCode === 13) { this.addKm() }
                        }}
                        type="number"
                        onInput={(e) => {
                          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                {this.state.stack &&
                  <div className="divContent floatW divS" onfocusout={event => this.setState({ stack: false })}>
                    <List
                      component="nav"
                      aria-labelledby="stack"
                    >
                      <ListItem key={3} button
                        className={(this.state.stack_value[2] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(3)}
                      >
                        <ListItemText id={3} primary="Area" />
                      </ListItem>

                      <ListItem key={2} button
                        className={(this.state.stack_value[1] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(2)}
                      >
                        <ListItemText id={2} primary="Caminho" />
                      </ListItem>

                      <ListItem key={1} button
                        className={(this.state.stack_value[0] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(1)}
                      >
                        <ListItemText id={1} primary="Aplicação" />
                      </ListItem>
                    </List>
                  </div>}
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { guid } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} guid={guid} res={res} />
    </div>)
}
export default Box;
