import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router";
import Moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from '@material-ui/icons/Tune';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PrintIcon from '@material-ui/icons/Print';

//Bico Hidráulico
import Equipment90088 from "../../assets/images/equipments/90088.png";
import Equipment90088A from "../../assets/images/equipments/90088A.png";
import Equipment90300 from "../../assets/images/equipments/90300.png";
import Equipment90300Bronze from "../../assets/images/equipments/90300-Bronze.png";
import Equipment90300Inox from "../../assets/images/equipments/90300-Inox.png";
import Equipment90400 from "../../assets/images/equipments/90400.png";
import Equipment90400Bronze from "../../assets/images/equipments/90400-Bronze.png";
import Equipment90400Inox from "../../assets/images/equipments/90400-Inox.png";
import Equipment90900 from "../../assets/images/equipments/90900.png";
import Equipment90900Bronze from "../../assets/images/equipments/90900-Bronze.png";
import Equipment90900Inox from "../../assets/images/equipments/90900-Inox.png";
import Equipment90900InoxCompleto from "../../assets/images/equipments/90900-InoxCompleto.png";
import EquipmentBicoArticulado from "../../assets/images/equipments/bicoArticuladoTT.png";
import EquipmentBicoBronze90 from "../../assets/images/equipments/bicoBronze90.png";
import EquipmentBicoBronze from "../../assets/images/equipments/bicoBronze.png";
import EquipmentBicoBronzeNipple from "../../assets/images/equipments/bicoBronzeNipple.png";
import EquipmentBicoNylon from "../../assets/images/equipments/bicoNylon.png";
import EquipmentBicoNylon90 from "../../assets/images/equipments/bicoNylon90.png";
// ELETROSTÁTICO
import EquipmentSerie90500 from "../../assets/images/equipments/90500.png";
import EquipmentSerie90600 from "../../assets/images/equipments/90600.png";
import EquipmentSerie90700 from "../../assets/images/equipments/90700.png";
import EquipmentSerie90800 from "../../assets/images/equipments/90800.png";
//Atomizador
import Equipment81mm from "../../assets/images/equipments/81mm.png";
import Equipment98mm from "../../assets/images/equipments/98mm.png";
import Equipment81mmD104 from "../../assets/images/equipments/81mmD104.png";
import Equipment75mmD125 from "../../assets/images/equipments/75mmD125.png";
import Equipment81mmInox from "../../assets/images/equipments/81mmInox.png";
import Equipment98mmInox from "../../assets/images/equipments/98mmInox.png";
import Equipment81mmD104Inox from "../../assets/images/equipments/81mmD104Inox.png";
import Equipment75mmD125Inox from "../../assets/images/equipments/75mmD125Inox.png";
import EquipmentDisco from "../../assets/images/equipments/atomizadorDisco.png";

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '36rem',
    },
    primary: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    secondary: {
        backgroundColor: '#e0e0e0',
    },
});

const equipmentNozzle = {
    'Pulverizador Leque Seletor - 90088': Equipment90088,
    'Pulverizador Leque Seletor - 90088A': Equipment90088A,
    'Pulverizador Leque TT03 - 90300': Equipment90300,
    'Pulverizador Leque TT03 - 90300 (Bronze)': Equipment90300Bronze,
    'Pulverizador Leque TT03 - 90300 (Inox)': Equipment90300Inox,
    'Pulverizador TT11 - 90400': Equipment90400,
    'Pulverizador TT11 - 90400 (Bronze)': Equipment90400Bronze,
    'Pulverizador TT11 - 90400 (Inox)': Equipment90400Inox,
    'Pulverizador TT09 - 90900': Equipment90900,
    'Pulverizador TT09 - 90900 (Bronze)': Equipment90900Bronze,
    'Pulverizador TT09 - 90900 (Inox)': Equipment90900Inox,
    'Pulverizador TT09 - 90900 (100% INOX)': Equipment90900InoxCompleto,
    'Bico Articulado TT - PN5579': EquipmentBicoArticulado,
    'Bico Bronze 90° - PN85517': EquipmentBicoBronze90,
    'Bico Bronze - PN85218': EquipmentBicoBronze,
    'Bico Bronze Nipple Extensor - PN01071': EquipmentBicoBronzeNipple,
    'Bico Nylon - PN05574': EquipmentBicoNylon,
    'Bico Nylon 90° - PN85519': EquipmentBicoNylon90,
};

const equipmentNozzleElectrostatic = {
    'Pulverizador Eletrostático Cerâmica - 90500': EquipmentSerie90500,
    'Pulverizador Eletrostático Core e Disco - 90600': EquipmentSerie90600,
    'Pulverizador Eletrostático Ponta Leque - 90700': EquipmentSerie90700,
    'Pulverizador Eletrostático TT11 - 90800': EquipmentSerie90800,
};

const equipmentAtomizer = {
    'Atomizador Rotativo 81mm': Equipment81mm,
    'Atomizador Rotativo 81mm (100% INOX)': Equipment81mmInox,
    'Atomizador Rotativo 98mm': Equipment98mm,
    'Atomizador Rotativo 98mm (100% INOX)': Equipment98mmInox,
    'Atomizador Rotativo 81mm D104': Equipment81mmD104,
    'Atomizador Rotativo 81mm D104 (100% INOX)': Equipment81mmD104Inox,
    'Atomizador Rotativo 75mm D125': Equipment75mmD125,
    'Atomizador Rotativo 75mm D125 (100% INOX)': Equipment75mmD125Inox,
    'Atomizador Rotativo Disco': EquipmentDisco,
}

class Equipment extends React.Component {
    state = {
        id: this.props.id,
        id_equipment: '',
        showModal: false,
        installationDate: new Date().toISOString().split('T')[0],
        typeEquipment: '',
        typeEquipmentName: '',
        electrostatic: 'electrostaticOff',
        equipment: '',
        manufacturerEquipment: '',
        nameEquipment: '',
        typeJet: '',
        sprayTip: '',
        configCore: '',
        configDisco: '',
        sprayTip: '',
        deflectionAngle: '',
        quantityNozzle: '',
        holes: '',
        jobType: [],
        screen: '',
        flowRegulator: '',
        angleBlades: '',
        quantityAtomizer: '',
        imageAtomizer: '',
        imageNozzle: '',
        equipmentData: [],
        registeredEquipment: [],
        expandedRows: [],
        equipmentTravicar: false,
        selectedEquipment: '',
        travicarEquipment: 'Travicar',
        formErrors: {
            typeEquipment: false,
            electrostatic: false,
            nameEquipment: false,
            installationDate: false,
            jobType: false,
            typeJet: false,
            sprayTip: false,
            deflectionAngle: false,
            quantityNozzle: false,
            holes: false,
            selectedEquipment: false,
            equipment: false,
            manufacturerEquipment: false,
            screen: false,
            flowRegulator: false,
            angleBlades: false,
            quantityAtomizer: false,
            travicarEquipment: false,
        },
        triedToSubmit: false,
        configurationData: [],
        calibrationData: [],
        equipmentStatus: {}
    };

    typeEquipmentGroups = {
        'Bico Hidráulico': [
            'Bico Hidráulico Core e Disco',
            'Bico Hidráulico Cerâmica',
            'Bico Hidráulico Jato Sólido',
            'Bico Hidráulico Jato Plano',
            'CP03/TT03',
            'CP09/TT09',
            'CP11/TT11',
        ],
        'Atomizador': [
            'Atomizador Rotativo Tela',
            'Atomizador Rotativo Disco'
        ]
    };
    typeJet = ['Plano', 'Cônico', 'Cônico (Cerâmica)', 'Sólido'];

    componentDidMount() {
        this.listEquipment();
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = name => event => {
        const value = event.target.value;

        if (name === 'typeEquipmentName') {
            const group = Object.keys(this.typeEquipmentGroups).find(group =>
                this.typeEquipmentGroups[group].includes(value)
            );

            this.setState({
                typeEquipment: group,
                typeEquipmentName: value
            });
        } else {
            this.setState({
                [name]: value,
            });
        }

        if (this.state.triedToSubmit) {
            this.setState(prevState => ({
                formErrors: {
                    ...prevState.formErrors,
                    [name]: !value
                }
            }));
        }
    };

    handleOpen = () => {
        this.setState({ showModal: true, id_equipment: '' })
    };

    handleClose = () => {
        this.setState({
            showModal: false,
            typeEquipment: '',
            typeEquipmentName: '',
            installationDate: new Date().toISOString().split('T')[0],
            equipmentTravicar: false,
            electrostatic: 'electrostaticOff',
            equipment: '',
            selectedEquipment: '',
            manufacturerEquipment: '',
            nameEquipment: '',
            typeJet: '',
            sprayTip: '',
            deflectionAngle: '',
            quantityNozzle: '',
            holes: '',
            screen: '',
            flowRegulator: '',
            angleBlades: '',
            quantityAtomizer: '',
            jobType: [],
        });
    };

    handleChangeElectrostatic = (status) => {
        this.setState({ electrostatic: status });
    };

    handleDateChange = (e) => {
        this.setState({ installationDate: e.target.value });
    };

    toggleRow = (id) => {
        const { expandedRows } = this.state;
        if (expandedRows.includes(id)) {
            this.setState({ expandedRows: expandedRows.filter((rowId) => rowId !== id) });
        } else {
            this.setState({ expandedRows: [...expandedRows, id] });
        }
    };

    handleChangeCheckbox = (event) => {
        this.setState({ equipmentTravicar: event.target.checked });
    };

    handleSelectedJob = (event) => {
        const { formErrors, triedToSubmit } = this.state;
        const job = event.target.name;
        const isChecked = event.target.checked;
        const newJobType = isChecked
            ? [...this.state.jobType, job]
            : this.state.jobType.filter(item => item !== job);

        this.setState({ jobType: newJobType });

        if (triedToSubmit) {
            this.setState({ formErrors: { ...formErrors, jobType: newJobType.length === 0 } });
        }
    };

    validateField = (fieldName, value) => {
        let fieldValid = value.trim() !== '';
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: !fieldValid
            }
        }));
    };

    registerEquipment = () => {
        const { link } = this.props;
        const {
            id, id_equipment, typeEquipment, typeEquipmentName, electrostatic, manufacturerEquipment,
            nameEquipment, installationDate, typeJet, sprayTip, deflectionAngle, quantityNozzle, holes,
            jobType, screen, flowRegulator, angleBlades, quantityAtomizer, selectedEquipment, equipment,
            travicarEquipment, equipmentTravicar, configCore, configDisco
        } = this.state;

        this.setState({ triedToSubmit: true }, () => {
            if (!this.equipmentsValidation()) {
                notification.open({
                    message: this.props.res.ERRO,
                    description: this.props.res.ERRO_MSG_INFORMACOES,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
                return;
            }

            let apiUrl = Config.server + '/plane_equipment';
            let method = id_equipment ? 'PUT' : 'POST';

            const formData = new URLSearchParams();
            if (id_equipment) {
                apiUrl += `/${id_equipment}`;
                formData.append('id', id_equipment);
            }

            formData.append('id_plane', id);
            formData.append('equipmentType', typeEquipment);
            formData.append('equipmentTypeName', typeEquipmentName);
            formData.append('electrostatic', electrostatic);
            formData.append('name', nameEquipment);
            formData.append('installationDate', installationDate);
            formData.append('jobType', jobType.join(','));

            if (typeEquipment === 'Bico Hidráulico') {
                formData.append('jetType', typeJet);
                formData.append('sprayTip', sprayTip);
                formData.append('deflectionAngle', deflectionAngle);
                formData.append('quantityNozzle', quantityNozzle);
                formData.append('holes', holes);
                formData.append('core', configCore);
                formData.append('disco', configDisco);
                if (equipmentTravicar) {
                    formData.append('equipment', selectedEquipment);
                    formData.append('manufacturerEquipment', travicarEquipment);
                } else {
                    formData.append('equipment', equipment);
                    formData.append('manufacturerEquipment', manufacturerEquipment);
                }
            } else if (typeEquipment === 'Atomizador') {
                formData.append('screen', screen);
                formData.append('flowRegulator', flowRegulator);
                formData.append('angleBlades', angleBlades);
                formData.append('quantityAtomizer', quantityAtomizer);
                if (equipmentTravicar) {
                    formData.append('equipment', selectedEquipment);
                    formData.append('manufacturerEquipment', travicarEquipment);
                } else {
                    formData.append('equipment', equipment);
                    formData.append('manufacturerEquipment', manufacturerEquipment);
                }
            }

            let options = {
                method: method,
                body: formData,
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    else return res.json();
                })
                .then((result) => {
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else {
                        let msg = id_equipment ? this.props.res.ITEM_ALTERADO : this.props.res.ITEM_INCLUIR;
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: msg,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                        const newId = result;
                        link(`/app/cadastro/aeronave/${id}/configuracao/${method === 'POST' ? newId : id_equipment}`);
                    }
                })
                .catch((error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                });
        });
    }

    openEditModal = (equipment) => {
        this.setState({
            id_equipment: equipment.id,
            showModal: true,
            typeEquipment: equipment.equipmentType,
            typeEquipmentName: equipment.equipmentTypeName,
            electrostatic: equipment.electrostatic,
            equipment: equipment.equipment,
            manufacturerEquipment: equipment.manufacturerEquipment,
            nameEquipment: equipment.name,
            typeJet: equipment.jetType,
            sprayTip: equipment.sprayTip,
            configCore: equipment.core,
            configDisco: equipment.disco,
            deflectionAngle: equipment.deflectionAngle,
            quantityNozzle: equipment.quantityNozzle,
            holes: equipment.holes,
            screen: equipment.screen,
            flowRegulator: equipment.flowRegulator,
            angleBlades: equipment.angleBlades,
            quantityAtomizer: equipment.quantityAtomizer,
            jobType: equipment.jobType.length > 0 ? equipment.jobType.split(',') : [],
            equipmentTravicar: equipment.manufacturerEquipment === 'Travicar' ? true : false,
            selectedEquipment: equipment.manufacturerEquipment === 'Travicar' ? equipment.equipment : '',
        });
    }

    listEquipment = () => {
        const { id } = this.state;
        const apiUrl = Config.server + '/plane_equipment';

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        const equipmentPlane = result.filter((equipments) => {
                            return equipments.id_plane == id;
                        })
                        this.setState({
                            equipmentData: result,
                            registeredEquipment: equipmentPlane,
                        });
                        this.checkAllEquipmentStatus(equipmentPlane);
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    deleteEquipment = (item) => {
        const apiUrl = Config.server + '/plane_equipment';
        const formData = new URLSearchParams();
        let descr = '';

        formData.append('id', item.id);
        descr = this.props.res.ITEM_EXCLUIDO;

        const options = {
            method: 'DELETE',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    notification.open({
                        message: this.props.res.SUCESSO,
                        description: descr,
                        icon: <MaterialIcon icon="check_circle" className="text-success" />
                    });

                    this.listEquipment();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    deleteCalibration = (item) => {
        const apiUrl = Config.server + '/plane_equipment_calibration';
        const formData = new URLSearchParams();
        let descr = '';

        formData.append('id_equipment', item.id);
        descr = this.props.res.ITEM_EXCLUIDO;

        const options = {
            method: 'DELETE',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    this.listEquipment();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    deleteConfiguration = (item) => {
        const apiUrl = Config.server + '/plane_equipment_configuration';
        const formData = new URLSearchParams();
        let descr = '';

        formData.append('id_equipment', item.id);
        descr = this.props.res.ITEM_EXCLUIDO;

        const options = {
            method: 'DELETE',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    this.listEquipment();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    deleteRegisters = (item) => {
        this.deleteCalibration(item);
        this.deleteConfiguration(item);
        this.deleteEquipment(item);
    }

    equipmentsValidation = () => {
        const {
            jobType, typeEquipment, typeJet, sprayTip, deflectionAngle,
            quantityNozzle, holes, screen, flowRegulator, angleBlades, quantityAtomizer,
            selectedEquipment, equipment, manufacturerEquipment, travicarEquipment, equipmentTravicar, configCore, configDisco
        } = this.state;

        let isValid = true;
        const errors = {};

        const requiredFieldsMap = {
            'Bico Hidráulico': ['typeEquipment', 'nameEquipment', 'installationDate', 'typeJet', 'deflectionAngle', 'quantityNozzle', 'holes'],
            'Atomizador': ['typeEquipment', 'nameEquipment', 'installationDate', 'screen', 'flowRegulator', 'angleBlades', 'quantityAtomizer']
        };

        if (typeJet === 'Cônico') {
            requiredFieldsMap['Bico Hidráulico'].push('configCore', 'configDisco');
        } else {
            requiredFieldsMap['Bico Hidráulico'].push('sprayTip');
        }

        const requiredFields = requiredFieldsMap[typeEquipment] || [];

        requiredFields.forEach(field => {
            if (!this.state[field]) {
                errors[field] = true;
                isValid = false;
            } else {
                errors[field] = false;
            }
        });

        if (equipmentTravicar) {
            if (!selectedEquipment) {
                errors.selectedEquipment = true;
                isValid = false;
            }
            if (!travicarEquipment) {
                errors.travicarEquipment = true;
                isValid = false;
            }
        } else {
            if (!equipment) {
                errors.equipment = true;
                isValid = false;
            }
        }

        if (jobType.length === 0) {
            errors.jobType = true;
            isValid = false;
        }

        this.setState({ formErrors: errors });
        return isValid;
    };

    readCalibration = (idEquipment) => {
        return new Promise((resolve, reject) => {
            const apiUrl = Config.server + `/plane_equipment_calibration/${idEquipment}`;
            const options = {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    else return res.json();
                })
                .then((result) => {
                    this.setState({
                        calibrationData: result
                    }, () => resolve(result));
                })
                .catch((error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                });
        });
    };

    readConfiguration = (idEquipment) => {
        return new Promise((resolve, reject) => {
            const apiUrl = Config.server + `/plane_equipment_configuration/${idEquipment}`;
            const options = {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    else return res.json();
                })
                .then((result) => {
                    this.setState({
                        configurationData: result
                    }, () => resolve(result));
                })
                .catch((error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                });
        });
    };

    downloadRelEquipment = (equip) => {
        let apiUrl = Config.server + '/relequipamento';
        let method = 'POST';
        this.setState({ triedToSubmit: true }, () => {

            const formData = new URLSearchParams();
            let positionNozzle;
            if (this.state?.configurationData[0]?.quantityNozzle > 0) {
                positionNozzle = JSON.parse(this.state.configurationData[0]?.nozzlePosition || "{}");
            }

            let positionAtomizer;
            if (this.state?.configurationData[0]?.quantityAtomizer > 0) {
                positionAtomizer = JSON.parse(this.state.configurationData[0]?.atomizerPosition || "{}");
            }

            formData.append('id_plane', equip.id_plane);
            formData.append('equipmentType', equip.equipmentType);
            formData.append('equipmentTypeName', equip.equipmentTypeName);
            formData.append('electrostatic', equip.electrostatic);
            formData.append('name', equip.name);
            formData.append('installationDate', equip.installationDate);
            formData.append('jobType', equip.jobType);
            formData.append('jetType', equip.jetType);
            formData.append('sprayTip', equip.sprayTip);
            formData.append('core', equip.core);
            formData.append('disco', equip.disco);
            formData.append('deflectionAngle', equip.deflectionAngle);
            formData.append('quantityNozzle', equip.quantityNozzle);
            formData.append('holes', equip.holes);
            formData.append('equipment', equip.equipment);
            formData.append('manufacturerEquipment', equip.manufacturerEquipment);
            formData.append('screen', equip.screen);
            formData.append('flowRegulator', equip.flowRegulator);
            formData.append('angleBlades', equip.angleBlades);
            formData.append('quantityAtomizer', equip.quantityAtomizer);
            formData.append('minimumSpeed', this.state.calibrationData[0]?.minimumSpeed);
            formData.append('averageSpeed', this.state.calibrationData[0]?.averageSpeed);
            formData.append('maximumSpeed', this.state.calibrationData[0]?.maximumSpeed);
            formData.append('applicationRange', this.state.calibrationData[0]?.applicationRange);
            formData.append('applicationFee', this.state.calibrationData[0]?.applicationFee);
            formData.append('sprayClassification', this.state.calibrationData[0]?.sprayClassification);
            formData.append('minimumCoveredArea', this.state.calibrationData[0]?.minimumCoveredArea);
            formData.append('averageCoveredArea', this.state.calibrationData[0]?.averageCoveredArea);
            formData.append('maximumCoveredArea', this.state.calibrationData[0]?.maximumCoveredArea);
            formData.append('minimumTotalVolume', this.state.calibrationData[0]?.minimumTotalVolume);
            formData.append('averageTotalVolume', this.state.calibrationData[0]?.averageTotalVolume);
            formData.append('maximumTotalVolume', this.state.calibrationData[0]?.maximumTotalVolume);
            formData.append('minimumEquipmentVolume', this.state.calibrationData[0]?.minimumEquipmentVolume);
            formData.append('averageEquipmentVolume', this.state.calibrationData[0]?.averageEquipmentVolume);
            formData.append('maximumEquipmentVolume', this.state.calibrationData[0]?.maximumEquipmentVolume);
            formData.append('minimumPressure', this.state.calibrationData[0]?.minimumPressure);
            formData.append('averagePressure', this.state.calibrationData[0]?.averagePressure);
            formData.append('maximumPressure', this.state.calibrationData[0]?.maximumPressure);
            formData.append('leftWing', equip.equipmentType === 'Atomizador'
                ? JSON.stringify({
                    position: positionAtomizer?.asaEsquerda.position,
                    inputFields: positionAtomizer?.asaEsquerda.inputFields,
                })
                : JSON.stringify(positionNozzle?.asaEsquerda));
            formData.append('leftCenter', equip.equipmentType === 'Atomizador'
                ? JSON.stringify({
                    position: positionAtomizer?.barrigaEsquerda.position,
                    inputFields: positionAtomizer?.barrigaEsquerda.inputFields,
                })
                : JSON.stringify(positionNozzle?.barrigaEsquerda));

            formData.append('rightCenter', equip.equipmentType === 'Atomizador'
                ? JSON.stringify({
                    position: positionAtomizer?.barrigaDireita.position,
                    inputFields: positionAtomizer?.barrigaDireita.inputFields,
                })
                : JSON.stringify(positionNozzle?.barrigaDireita));

            formData.append('rightWing', equip.equipmentType === 'Atomizador'
                ? JSON.stringify({
                    position: positionAtomizer?.asaDireita.position,
                    inputFields: positionAtomizer?.asaDireita.inputFields,
                })
                : JSON.stringify(positionNozzle?.asaDireita));
            formData.append('configurationData', this.state.configurationData.length);
            formData.append('calibrationData', this.state.calibrationData.length);


            let options = {
                method: method,
                body: formData,
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Erro no servidor: ${response.statusText}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    if (blob.type === 'application/pdf') {
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', `Relatorio-${equip.equipment}-${Moment(new Date()).format("DD-MM-YYYY")}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                        document.body.removeChild(link);
                    } else if (blob.type === 'application/json') {
                        return blob.text().then(text => {
                            const errorData = JSON.parse(text);
                            notification.open({
                                message: this.props.res.ERRO,
                                description: this.props.res.ERRO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        });
                    } else {
                        return blob.text().then(text => {
                            const errorResponse = JSON.parse(text);
                            console.error('Erro recebido:', errorResponse);
                        });
                    }
                })
                .catch(error => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                });
        });
    }


    checkEquipmentStatus = (idEquipment) => {
        return Promise.all([
            this.readConfiguration(idEquipment),
            this.readCalibration(idEquipment)
        ]).then(([configuration, calibration]) => {
            const isConfigurationComplete = configuration &&
                Object.keys(configuration).length > 0 &&
                Object.values(configuration).every(value => value !== null && value !== '');

            const isCalibrationComplete = calibration &&
                Object.keys(calibration).length > 0 &&
                Object.values(calibration).every(value => value !== null && value !== '');

            return {
                id: idEquipment,
                isConfigurationComplete,
                isCalibrationComplete
            };
        }).catch(error => {
            notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
            });
            return {
                id: idEquipment,
                isConfigurationComplete: false,
                isCalibrationComplete: false
            };
        });
    };

    checkAllEquipmentStatus = (teste) => {
        const statusPromises = teste.map(equipment =>
            this.checkEquipmentStatus(equipment.id)
        );

        Promise.all(statusPromises)
            .then(results => {
                const newStatus = results.reduce((acc, current) => {
                    acc[current.id] = {
                        isConfigurationComplete: current.isConfigurationComplete,
                        isCalibrationComplete: current.isCalibrationComplete
                    };
                    return acc;
                }, {});

                this.setState({ equipmentStatus: newStatus });
            })
            .catch(error => {
                notification.open({
                    message: this.props.res.ERRO,
                    description: this.props.res.ERRO_MSG,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
            });
    };

    render() {
        const { link, res, classes } = this.props;
        const { id, manufacturerEquipment, registeredEquipment, showModal, typeEquipment, typeEquipmentName, electrostatic, installationDate, configDisco, configCore,
            expandedRows, equipmentTravicar, selectedEquipment, travicarEquipment, equipment, nameEquipment, typeJet,
            sprayTip, deflectionAngle, quantityNozzle, holes, jobType, flowRegulator, angleBlades, quantityAtomizer, screen
        } = this.state;

        return (
            <div>
                <div className="containerTop">
                    <div>
                        <h1>{res.EQUIPAMENTO}</h1>
                    </div>
                    <div className="centerTop" />
                </div>
                <div className="form-group notTooBig" style={{ margin: '0', width: '100%', display: 'flex' }}>
                    <Button
                        onClick={this.handleOpen}
                        variant="contained"
                        color="primary"
                        className="popUpButton"
                        style={{ width: 'fit-content' }}
                    >
                        {res.CADASTRAR}
                    </Button>
                </div>
                {registeredEquipment.length === 0
                    ?
                    ''
                    :
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{res.TIPO_EQUIPAMENTO}</TableCell>
                                    <TableCell>{res.EQUIPAMENTO}</TableCell>
                                    <TableCell>{res.IDENTIFICADOR}</TableCell>
                                    <TableCell>{res.TIPO_SERVICO}</TableCell>
                                    <TableCell>{res.FABRICANTE}</TableCell>
                                    <TableCell>{res.DATA_INSTALACAO}</TableCell>
                                    <TableCell>{res.CADASTRO}</TableCell>
                                    <TableCell>{res.ACOES}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {registeredEquipment.map((equipment) => {
                                    const status = this.state.equipmentStatus[equipment.id];
                                    const statusText = status && status.isConfigurationComplete && status.isCalibrationComplete ? 'Completo' : 'Incompleto';
                                    const statusColor = statusText === 'Completo' ? 'green' : 'red';

                                    return (
                                        <React.Fragment key={equipment.id}>
                                            <TableRow
                                                className={expandedRows.includes(equipment.id) ? 'expanded' : ''}
                                            >
                                                <TableCell>{equipment.equipmentTypeName}</TableCell>
                                                <TableCell>{equipment.equipment}</TableCell>
                                                <TableCell>{equipment.name}</TableCell>
                                                <TableCell>
                                                    <ul style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '0' }}>
                                                        {equipment.jobType.split(',').map((jobType, index) => (
                                                            <li key={index}>{jobType.trim()}</li>
                                                        ))}
                                                    </ul>
                                                </TableCell>
                                                <TableCell>{equipment.manufacturerEquipment}</TableCell>
                                                <TableCell>{Moment(equipment.installationDate).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell style={{ color: statusColor, fontWeight: '600' }}>{statusText}</TableCell>
                                                <TableCell>
                                                    <PrintIcon
                                                        color="primary"
                                                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                                                        onClick={() => this.downloadRelEquipment(equipment)}
                                                    />
                                                    <EditIcon
                                                        color="primary"
                                                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                                                        onClick={() => this.openEditModal(equipment)}
                                                    />
                                                    <DeleteIcon
                                                        color="primary"
                                                        style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                                                        onClick={() => this.deleteRegisters(equipment)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {expandedRows.includes(equipment.id)
                                                        ?
                                                        <KeyboardArrowUpIcon
                                                            color="primary"
                                                            style={{ cursor: 'pointer', fontSize: '2rem' }}
                                                            onClick={() => this.toggleRow(equipment.id)}
                                                        />
                                                        :
                                                        <KeyboardArrowDownIcon
                                                            color="primary"
                                                            style={{ cursor: 'pointer', fontSize: '2rem' }}
                                                            onClick={() => this.toggleRow(equipment.id)}
                                                        />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            {expandedRows.includes(equipment.id) && (
                                                <TableRow>
                                                    <TableCell colSpan="7">
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1, textAlign: 'center' }}>
                                                                <button
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        border: 'none',
                                                                        borderRadius: '1rem',
                                                                        padding: '1rem',
                                                                        margin: '1rem 0',
                                                                        width: '16rem'
                                                                    }}
                                                                    onClick={() => (link(`/app/cadastro/aeronave/${id}/configuracao/${equipment.id}`))}
                                                                >
                                                                    <div>
                                                                        <h4 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                                            {res.CONFIGURACAO}
                                                                            <SettingsIcon style={{ margin: '0 0.5rem' }} />
                                                                        </h4>
                                                                    </div>
                                                                    <div>
                                                                        {res.CADASTRO}:
                                                                        <span style={{ marginLeft: '0.5rem', fontWeight: '600', color: this.state.equipmentStatus[equipment.id]?.isConfigurationComplete ? 'green' : 'red' }}>
                                                                            {this.state.equipmentStatus[equipment.id]?.isConfigurationComplete ? 'Completo' : 'Incompleto'}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            <div style={{ flex: 1, textAlign: 'center' }}>
                                                                <button style={{
                                                                    cursor: 'pointer',
                                                                    border: 'none',
                                                                    borderRadius: '1rem',
                                                                    padding: '1rem',
                                                                    margin: '1rem 0',
                                                                    width: '16rem'
                                                                }}
                                                                    onClick={() => (link(`/app/cadastro/aeronave/${id}/calibracao/${equipment.id}`))}
                                                                >
                                                                    <div>
                                                                        <h4
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                whiteSpace: 'nowrap'
                                                                            }}
                                                                        >
                                                                            {res.CALIBRACAO}
                                                                            <TuneIcon style={{ margin: '0 0.5rem' }} />
                                                                        </h4>
                                                                    </div>
                                                                    <div>
                                                                        {res.CADASTRO}:
                                                                        <span style={{ marginLeft: '0.5rem', fontWeight: '600', color: this.state.equipmentStatus[equipment.id]?.isCalibrationComplete ? 'green' : 'red' }}>
                                                                            {this.state.equipmentStatus[equipment.id]?.isCalibrationComplete ? 'Completo' : 'Incompleto'}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                }
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <div className={classes.paper}>
                        <div style={{ backgroundColor: '#2D3238', display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
                            <div className="leftDivHeader"></div>
                            <h3 style={{ color: 'white', whiteSpace: 'nowrap' }} className="centerDivHeader">{res.CADASTRAR_EQUIPAMENTO}</h3>
                            <div className="rightDivHeader">
                                <Button
                                    size="small"
                                    className="popUpButton"
                                    onClick={this.handleClose}
                                >
                                    <CloseIcon style={{ color: 'white' }} />
                                </Button>
                            </div>
                        </div>
                        <div style={{ backgroundColor: 'white', padding: '1rem', overflow: 'auto', maxHeight: '44rem' }}>
                            <div>
                                <FormControl style={{ width: '100%', paddingTop: '1rem' }} required>
                                    <InputLabel htmlFor="typeEquipmentName">{res.TIPO_EQUIPAMENTO}</InputLabel>
                                    <Select
                                        onChange={this.handleChange('typeEquipmentName')}
                                        value={typeEquipmentName}
                                    >
                                        <MenuItem disabled value="">Selecione o tipo de equipamento</MenuItem>
                                        {Object.values(this.typeEquipmentGroups).flat().map((type, key) => (
                                            <MenuItem key={key} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={equipmentTravicar}
                                            onChange={this.handleChangeCheckbox}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Equipamento Travicar"
                                />
                            </div>
                            {typeEquipment === 'Bico Hidráulico'
                                ?
                                <div>
                                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                        <InputLabel htmlFor="electrostatic">{res.ELETROSTATICO}</InputLabel>
                                        <div style={{ marginTop: '3rem' }}>
                                            <Button
                                                style={{ margin: '0.25rem' }}
                                                variant="contained"
                                                value={electrostatic}
                                                onClick={() => this.handleChangeElectrostatic('electrostaticOn')}
                                                className={electrostatic === 'electrostaticOn' ? classes.primary : classes.secondary}
                                            >
                                                {res.SIM}
                                            </Button>
                                            <Button
                                                style={{ margin: '0.25rem' }}
                                                variant="contained"
                                                value={electrostatic}
                                                onClick={() => this.handleChangeElectrostatic('electrostaticOff')}
                                                className={electrostatic === 'electrostaticOff' ? classes.primary : classes.secondary}
                                            >
                                                {res.NAO}
                                            </Button>
                                        </div>
                                    </FormControl>
                                    {equipmentTravicar === true
                                        ?
                                        <div>
                                            <div>
                                                <FormControl
                                                    style={{ width: '100%', paddingTop: '1rem' }}
                                                    error={this.state.triedToSubmit && this.state.formErrors.selectedEquipment}
                                                    required
                                                >
                                                    <InputLabel htmlFor="selectedEquipment">{res.EQUIPAMENTO}</InputLabel>
                                                    <Select
                                                        onChange={this.handleChange('selectedEquipment')}
                                                        value={this.state.selectedEquipment}
                                                        id="selectedEquipment"
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: '24rem',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem disabled value="">Selecione o equipamento</MenuItem>
                                                        {Object.keys(electrostatic === 'electrostaticOff' ? equipmentNozzle : equipmentNozzleElectrostatic).map((item, key) => (
                                                            <MenuItem key={key} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {this.state.triedToSubmit && this.state.formErrors.selectedEquipment && (
                                                        <FormHelperText style={{ color: 'red' }}>
                                                            {`${res.EQUIPAMENTO} ${res.OBRIGATORIO}`}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                                {selectedEquipment && (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img
                                                            style={{ width: '12rem' }}
                                                            src={electrostatic === 'electrostaticOff' ? equipmentNozzle[selectedEquipment] : equipmentNozzleElectrostatic[selectedEquipment]}
                                                            alt="Imagem do Produto"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                <TextField
                                                    id="manufacturerEquipment"
                                                    onChange={this.handleChange('manufacturerEquipment')}
                                                    value={travicarEquipment}
                                                    label={res.FABRICANTE}
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                        :
                                        <div>
                                            <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                <TextField
                                                    onChange={this.handleChange('equipment')}
                                                    id="typeEquipment"
                                                    label={res.EQUIPAMENTO}
                                                    value={equipment}
                                                    required
                                                    error={this.state.triedToSubmit && this.state.formErrors.equipment}
                                                    helperText={this.state.triedToSubmit && this.state.formErrors.equipment ? `${res.EQUIPAMENTO} ${res.OBRIGATORIO}` : ""}
                                                />
                                            </FormControl>
                                            <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                <TextField
                                                    onChange={this.handleChange('manufacturerEquipment')}
                                                    id="manufacturerEquipment"
                                                    label={res.FABRICANTE}
                                                    value={manufacturerEquipment}
                                                    required
                                                    error={this.state.triedToSubmit && this.state.formErrors.manufacturerEquipment}
                                                    helperText={this.state.triedToSubmit && this.state.formErrors.manufacturerEquipment ? `${res.FABRICANTE} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                                />
                                            </FormControl>
                                        </div>
                                    }
                                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                        <TextField
                                            onChange={this.handleChange('nameEquipment')}
                                            id="nameEquipment"
                                            label={res.IDENTIFICADOR}
                                            value={this.state.nameEquipment}
                                            required
                                            error={this.state.triedToSubmit && this.state.formErrors.nameEquipment}
                                            helperText={this.state.triedToSubmit && this.state.formErrors.nameEquipment ? `${res.IDENTIFICADOR} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                        <TextField
                                            id="installationDate"
                                            label={res.DATA_INSTALACAO}
                                            type="date"
                                            onChange={this.handleDateChange}
                                            value={installationDate}
                                            required
                                            error={this.state.triedToSubmit && this.state.formErrors.installationDate}
                                            helperText={this.state.triedToSubmit && this.state.formErrors.installationDate ? `${res.DATA_INSTALACAO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                        />
                                    </FormControl>
                                    <FormControl
                                        style={{ width: '100%', paddingTop: '1rem' }}
                                        required
                                        error={this.state.triedToSubmit && this.state.formErrors.typeJet}
                                    >
                                        <InputLabel htmlFor="typeJet" style={{ paddingTop: '1rem' }}>{res.TIPO_JATO}</InputLabel>
                                        <Select
                                            onChange={this.handleChange('typeJet')}
                                            value={typeJet}
                                        >
                                            <MenuItem disabled value="">Selecione o tipo de jato</MenuItem>
                                            {this.typeJet.map((item, key) => (
                                                <MenuItem key={key} value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                        {this.state.triedToSubmit && this.state.formErrors.typeJet && (
                                            <FormHelperText style={{ color: 'red' }}>
                                                {`${res.TIPO_JATO} ${res.EQUIPAMENTO_OBRIGATORIO}`}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    {typeJet === 'Cônico'
                                        ?
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('configCore')}
                                                id="configCore"
                                                label={res.CORE}
                                                value={configCore}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.configCore}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.configCore ? `${res.CORE} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                            <TextField
                                                style={{ marginTop: '1rem' }}
                                                onChange={this.handleChange('configDisco')}
                                                id="configDisco"
                                                label={res.DISCO}
                                                value={configDisco}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.configDisco}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.configDisco ? `${res.DISCO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        :
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('sprayTip')}
                                                id="sprayTip"
                                                label={res.PONTA_PULVERIZACAO}
                                                value={sprayTip}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.sprayTip}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.sprayTip ? `${res.PONTA_PULVERIZACAO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                    }
                                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                        <TextField
                                            onChange={this.handleChange('deflectionAngle')}
                                            id="deflectionAngle"
                                            label={res.ANGULO_DEFLEXAO}
                                            value={deflectionAngle}
                                            required
                                            error={this.state.triedToSubmit && this.state.formErrors.deflectionAngle}
                                            helperText={this.state.triedToSubmit && this.state.formErrors.deflectionAngle ? `${res.ANGULO_DEFLEXAO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: '100%', padding: '1rem 0' }}>
                                        <TextField
                                            onChange={this.handleChange('holes')}
                                            id="holes"
                                            type="number"
                                            label={res.QUANTIDADE_FUROS}
                                            value={holes}
                                            required
                                            error={this.state.triedToSubmit && this.state.formErrors.holes}
                                            helperText={this.state.triedToSubmit && this.state.formErrors.holes ? `${res.QUANTIDADE_FUROS} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: '100%', padding: '1rem 0' }}>
                                        <TextField
                                            onChange={this.handleChange('quantityNozzle')}
                                            id="quantityNozzle"
                                            type="number"
                                            label={res.QUANTIDADE_BICOS}
                                            value={quantityNozzle}
                                            required
                                            error={this.state.triedToSubmit && this.state.formErrors.quantityNozzle}
                                            helperText={this.state.triedToSubmit && this.state.formErrors.quantityNozzle ? `${res.QUANTIDADE_BICOS} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: '100%', paddingTop: '1rem', display: 'contents' }}>
                                        <span style={{
                                            color: this.state.triedToSubmit && this.state.formErrors.jobType ? 'red' : 'inherit',
                                            marginRight: '1rem',
                                            fontSize: '1rem'
                                        }}>
                                            {res.TIPO_SERVICO}: *
                                        </span>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Inseticida"
                                                    color="primary"
                                                    onChange={this.handleSelectedJob}
                                                    checked={jobType.includes('Inseticida')}
                                                />
                                            }
                                            label="Inseticida"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Herbicida"
                                                    color="primary"
                                                    onChange={this.handleSelectedJob}
                                                    checked={jobType.includes('Herbicida')}
                                                />
                                            }
                                            label="Herbicida"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Fungicida"
                                                    color="primary"
                                                    onChange={this.handleSelectedJob}
                                                    checked={jobType.includes('Fungicida')}
                                                />
                                            }
                                            label="Fungicida"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Semeadura"
                                                    color="primary"
                                                    onChange={this.handleSelectedJob}
                                                    checked={jobType.includes('Semeadura')}
                                                />
                                            }
                                            label="Semeadura"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="Adubação"
                                                    color="primary"
                                                    onChange={this.handleSelectedJob}
                                                    checked={jobType.includes('Adubação')}
                                                />
                                            }
                                            label="Adubação"
                                        />
                                    </FormControl>
                                    {this.state.triedToSubmit && this.state.formErrors.jobType && (
                                        <p style={{ color: 'red' }}>{`${res.TIPO_SERVICO} ${res.EQUIPAMENTO_OBRIGATORIO}`}</p>
                                    )}
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '1rem' }}>
                                        <Button variant="contained" color="primary" className="popUpButton" onClick={this.registerEquipment}>
                                            <SaveIcon className="iconMapS" />
                                            {res.SALVAR}
                                        </Button>
                                    </div>
                                </div>
                                :
                                typeEquipment === 'Atomizador'
                                    ?
                                    <div>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <InputLabel htmlFor="typeEquipment">{res.ELETROSTATICO}</InputLabel>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Button
                                                    style={{ margin: '0.25rem' }}
                                                    variant="contained"
                                                    value={electrostatic}
                                                    onClick={() => this.handleChangeElectrostatic('electrostaticOn')}
                                                    className={electrostatic === 'electrostaticOn' ? classes.primary : classes.secondary}
                                                >
                                                    {res.SIM}
                                                </Button>
                                                <Button
                                                    style={{ margin: '0.25rem' }}
                                                    variant="contained"
                                                    value={electrostatic}
                                                    onClick={() => this.handleChangeElectrostatic('electrostaticOff')}
                                                    className={electrostatic === 'electrostaticOff' ? classes.primary : classes.secondary}
                                                >
                                                    {res.NAO}
                                                </Button>
                                            </div>
                                        </FormControl>
                                        {equipmentTravicar === true
                                            ?
                                            <div>
                                                <div>
                                                    <FormControl
                                                        style={{ width: '100%', paddingTop: '1rem' }}
                                                        error={this.state.triedToSubmit && this.state.formErrors.selectedEquipment}
                                                        required
                                                    >
                                                        <InputLabel htmlFor="selectedEquipment">{res.EQUIPAMENTO}</InputLabel>
                                                        <Select
                                                            onChange={this.handleChange('selectedEquipment')}
                                                            value={this.state.selectedEquipment}
                                                            id="selectedEquipment"
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: '24rem',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">Selecione o equipamento</MenuItem>
                                                            {Object.keys(equipmentAtomizer).map((item, key) => (
                                                                <MenuItem key={key} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {this.state.triedToSubmit && this.state.formErrors.selectedEquipment && (
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {`${res.EQUIPAMENTO} ${res.OBRIGATORIO}`}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    {selectedEquipment && (
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <img
                                                                style={{ width: '12rem' }}
                                                                src={equipmentAtomizer[selectedEquipment]}
                                                                alt="Imagem do Produto"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                    <TextField
                                                        id="manufacturerEquipment"
                                                        onChange={this.handleChange('manufacturerEquipment')}
                                                        value={travicarEquipment}
                                                        label={res.FABRICANTE}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </div>
                                            :
                                            <div>
                                                <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                    <TextField
                                                        onChange={this.handleChange('equipment')}
                                                        id="equipment"
                                                        label={res.EQUIPAMENTO}
                                                        value={equipment}
                                                        required
                                                        error={this.state.triedToSubmit && this.state.formErrors.equipment}
                                                        helperText={this.state.triedToSubmit && this.state.formErrors.equipment ? `${res.EQUIPAMENTO} ${res.OBRIGATORIO}` : ""}
                                                    />
                                                </FormControl>
                                                <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                                    <TextField
                                                        onChange={this.handleChange('manufacturerEquipment')}
                                                        id="manufacturerEquipment"
                                                        label={res.FABRICANTE}
                                                        value={manufacturerEquipment}
                                                        required
                                                        error={this.state.triedToSubmit && this.state.formErrors.manufacturerEquipment}
                                                        helperText={this.state.triedToSubmit && this.state.formErrors.manufacturerEquipment ? `${res.FABRICANTE} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('nameEquipment')}
                                                id="nameEquipment"
                                                label={res.IDENTIFICADOR}
                                                value={nameEquipment}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.nameEquipment}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.nameEquipment ? `${res.IDENTIFICADOR} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                id="installationDate"
                                                label={res.DATA_INSTALACAO}
                                                onChange={this.handleDateChange}
                                                type="date"
                                                value={installationDate}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.installationDate}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.installationDate ? `${res.DATA_INSTALACAO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('screen')}
                                                id="screen"
                                                label={res.TELA}
                                                value={screen}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.screen}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.screen ? `${res.TELA} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('flowRegulator')}
                                                id="flowRegulator"
                                                label={res.REGULADOR_VAZAO}
                                                value={flowRegulator}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.flowRegulator}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.flowRegulator ? `${res.REGULADOR_VAZAO} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                onChange={this.handleChange('angleBlades')}
                                                id="angleBlades"
                                                label={res.ANGULO_PAS}
                                                value={angleBlades}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.angleBlades}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.angleBlades ? `${res.ANGULO_PAS} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', padding: '1rem 0' }}>
                                            <TextField
                                                onChange={this.handleChange('quantityAtomizer')}
                                                id="quantityAtomizer"
                                                type="number"
                                                label={res.QUANTIDADE_ATOMIZADORES}
                                                value={quantityAtomizer}
                                                required
                                                error={this.state.triedToSubmit && this.state.formErrors.quantityAtomizer}
                                                helperText={this.state.triedToSubmit && this.state.formErrors.quantityAtomizer ? `${res.QUANTIDADE_ATOMIZADORES} ${res.EQUIPAMENTO_OBRIGATORIO}` : ""}
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem', display: 'contents' }}>
                                            <span style={{
                                                color: this.state.triedToSubmit && this.state.formErrors.jobType ? 'red' : 'inherit',
                                                marginRight: '1rem',
                                                fontSize: '1rem'
                                            }}>
                                                {res.TIPO_SERVICO}: *
                                            </span>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Inseticida"
                                                        color="primary"
                                                        onChange={this.handleSelectedJob}
                                                        checked={jobType.includes('Inseticida')}
                                                    />
                                                }
                                                label="Inseticida"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Herbicida"
                                                        color="primary"
                                                        onChange={this.handleSelectedJob}
                                                        checked={jobType.includes('Herbicida')}
                                                    />
                                                }
                                                label="Herbicida"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Fungicida"
                                                        color="primary"
                                                        onChange={this.handleSelectedJob}
                                                        checked={jobType.includes('Fungicida')}
                                                    />
                                                }
                                                label="Fungicida"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Semeadura"
                                                        color="primary"
                                                        onChange={this.handleSelectedJob}
                                                        checked={jobType.includes('Semeadura')}
                                                    />
                                                }
                                                label="Semeadura"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="Adubação"
                                                        color="primary"
                                                        onChange={this.handleSelectedJob}
                                                        checked={jobType.includes('Adubação')}
                                                    />
                                                }
                                                label="Adubação"
                                            />
                                        </FormControl>
                                        {this.state.triedToSubmit && this.state.formErrors.jobType && (
                                            <p style={{ color: 'red' }}>{`${res.TIPO_SERVICO} ${res.EQUIPAMENTO_OBRIGATORIO}`}</p>
                                        )}
                                        <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '1rem' }}>
                                            <Button variant="contained" color="primary" className="popUpButton" onClick={this.registerEquipment}>
                                                <SaveIcon className="iconMapS" />
                                                {res.SALVAR}
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }} disabled>
                                            <InputLabel htmlFor="typeEquipment">{res.ELETROSTATICO}</InputLabel>
                                            <div style={{ marginTop: '3rem' }}>
                                                <Button
                                                    style={{ margin: '0.25rem' }}
                                                    variant="contained"
                                                    disabled
                                                >
                                                    {res.SIM}
                                                </Button>
                                                <Button
                                                    style={{ margin: '0.25rem' }}
                                                    variant="contained"
                                                    disabled
                                                >
                                                    {res.NAO}
                                                </Button>
                                            </div>
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }} disabled>
                                            <TextField
                                                id="equipment"
                                                label={res.EQUIPAMENTO}
                                                disabled
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                id="manufacturer"
                                                label={res.FABRICANTE}
                                                disabled
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                                            <TextField
                                                id="installationDate"
                                                label={res.DATA_INSTALACAO}
                                                type="date"
                                                value={installationDate}
                                                disabled
                                            />
                                        </FormControl>
                                    </div>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

Equipment.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Equipment);

const Box = (props) => {
    const { link, res } = props;
    let { id } = useParams();
    return (
        <div style={{ marginTop: '2rem' }}>
            <TextFields1 link={link} id={id} res={res} />
        </div>)
}

export default Box;