import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import CompanyList from './../../routes/company/List';
import CompanyEdit from './../../routes/company/Edit';

import UserList from './../../routes/user/List';
import UserEdit from './../../routes/user/Edit';
import UserExtrato from './../../routes/user/Extrato';

import AlertEdit from './../../routes/alert/Edit';
import AlertList from './../../routes/alert/List';

import PartnerList from './../../routes/partner/List';
import PartnerEdit from './../../routes/partner/Edit';
import PartnerLink from './../../routes/partner/link';

import AircraftList from './../../routes/aircraft/List';
import AircraftEdit from './../../routes/aircraft/Edit';
import AircraftConfiguration from '../../routes/aircraft/registerComponents/AircraftConfiguration';
import AssessmentTests from '../../routes/aircraft/registerComponents/AssessmentTests';
import Calibration from '../../routes/aircraft/registerComponents/Calibration';
import Maintenance from '../../routes/aircraft/registerComponents/Maintenance';

import ModuleList from './../../routes/module/List';
import ModuleEdit from './../../routes/module/Edit';

import DGPSList from './../../routes/dgps/List';
import DGPSEdit from './../../routes/dgps/Edit';

import Draw from './../../routes/job_draw/Map';
import View from './../../routes/job_load/Map';

import Estimate from '../../routes/job_estimate/Map';
import Stats from '../../routes/job_stats/Map';
import KMZMap from '../../routes/job_report/KMZMap';
//import Analyze from '../../routes/job_analyze/Map';
import Appraisal from '../../routes/job_check/Map';

import JobList from '../../routes/job/List';
import JobCalendar from '../../routes/job_calendar/Calendar';
import PointMap from '../../routes/point/Map';

import Location from '../../routes/monitoring/Location';
import UpdateGPS from '../../routes/monitoring/Edit';

import Climate from '../../routes/climate/Map'
import ClimateEdit from '../../routes/climate/Edit'
import ClimateList from '../../routes/climate/List'

import Cost from '../../routes/cost/Edit';
import Translate from '../../routes/job_export/List';

import AdminBalance from './../../routes/administration/Balance';

import Calibracao from './../../routes/calibration/Edit';
import Qualidade from './../../routes/job_analyze/Map';

import AgronomistList from './../../routes/technical_team/agronomist/List';
import AgronomistEdit from './../../routes/technical_team/agronomist/Edit';

import TechnicianList from './../../routes/technical_team/technician/List';
import TechnicianEdit from './../../routes/technical_team/technician/Edit';

import PilotList from './../../routes/technical_team/pilot/List';
import PilotEdit from './../../routes/technical_team/pilot/Edit';

import ClientList from './../../routes/client/List';
import ClientEdit from './../../routes/client/Edit';

import JobPO from './../../routes/job_po/Edit';

import Reports from '../../routes/job_report/Map';

import QualityDashboard from '../../routes/dashboard/dashboard_quality';

import ReportsMAPA from '../../routes/reportsMapa/List';
import EditReportsMAPA from '../../routes/reportsMapa/Edit';

//teste
import Teste from '../../routes/teste/Teste';
import Dashboard from '../../routes/dashboard/Dashboard';

class Content extends React.Component {
    render() {
        const { history, link, res } = this.props;
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/app/cadastro/empresa"><Redirect to="/app/cadastro/empresa/list" /></Route>
                    <Route path="/app/cadastro/empresa/list"><CompanyList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/empresa/add"><CompanyEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/empresa/edit/:id"><CompanyEdit link={link} res={res} /></Route>

                    <Route exact path="/app/cadastro/usuario"><Redirect to="/app/cadastro/usuario/list" /></Route>
                    <Route path="/app/cadastro/usuario/list"><UserList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/usuario/add"><UserEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/usuario/edit/:id"><UserEdit link={link} res={res} /></Route>
                    {/* <Route path="/app/perfil"><UserProfile link={link} res={res} /></Route> */}
                    
                    <Route exact path="/app/cadastro/alerta"><AlertEdit link={link} res={res} /></Route>
                    <Route exact path="/app/alerta"><AlertList link={link} res={res} /></Route>
                    

                    <Route exact path="/app/cadastro/parceiro"><Redirect to="/app/cadastro/parceiro/list" /></Route>
                    <Route path="/app/cadastro/parceiro/list"><PartnerList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/parceiro/add"><PartnerEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/parceiro/edit/:id"><PartnerEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/link"><PartnerLink link={link} res={res} /></Route>

                    <Route path="/app/extrato"><UserExtrato link={link} res={res} /></Route>

                    <Route exact path="/app/cadastro/modulo"><Redirect to="/app/cadastro/modulo/list" /></Route>
                    <Route path="/app/cadastro/modulo/list"><ModuleList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/modulo/add"><ModuleEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/modulo/edit/:id"><ModuleEdit link={link} res={res} /></Route>

                    <Route exact path="/app/cadastro/aeronave"><Redirect to="/app/cadastro/aeronave/list" /></Route>
                    <Route path="/app/cadastro/aeronave/list"><AircraftList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/aeronave/add"><AircraftEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/aeronave/edit/:id"><AircraftEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/aeronave/:id/configuracao/:id_equipment"><AircraftConfiguration link={link} res={res} /></Route>
                    <Route path="/app/cadastro/aeronave/:id/calibracao/:id_equipment"><Calibration link={link} res={res} /></Route>
                    {/* <Route path="/app/cadastro/aeronave/:id/manutencao/:id_equipment"><Maintenance link={link} res={res} /></Route> */}
                    {/* <Route path="/app/cadastro/aeronave/:id/avalicao-testes/:id_equipment"><AssessmentTests link={link} res={res} /></Route> */}

                    <Route exact path="/app/cadastro/dgps"><Redirect to="/app/cadastro/dgps/list" /></Route>
                    <Route path="/app/cadastro/dgps/list"><DGPSList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/dgps/add"><DGPSEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/dgps/edit/:id"><DGPSEdit link={link} res={res} /></Route>
                    <Route path="/app/draw/:local"><Draw link={link} res={res} /></Route>
                    <Route path="/app/draw"><Draw link={link} res={res} /></Route>
                    <Route path="/app/view"><View link={link} res={res} /></Route>

                    <Route path="/app/estimativa/:type/:guid"><Estimate link={link} res={res} /></Route>
                    <Route path="/app/estimativa/:type"><Estimate link={link} res={res} /></Route>
                    <Route path="/app/estimativa"><Estimate link={link} res={res} /></Route>
                    <Route path="/app/qualidade/:guid"><Qualidade link={link} res={res} /></Route>
                    <Route path="/app/qualidade"><Qualidade link={link} res={res} /></Route>


                    <Route path="/app/ciclo"><JobList link={link} res={res} /></Route>
                    <Route path="/app/agenda"><JobCalendar link={link} res={res} /></Route>                    
                    <Route path="/app/preorcamento/:guid"><JobPO link={link} res={res} /></Route>
                    <Route path="/app/preorcamento"><JobPO link={link} res={res} /></Route>
                    <Route path="/app/cadastro/waypoint"><PointMap link={link} res={res} type="W" /></Route>
                    <Route path="/app/cadastro/obstaculo"><PointMap link={link} res={res} type="O" /></Route>


                    <Route path="/app/location"><Location link={link} res={res} /></Route>
                    <Route path="/app/updategps"><UpdateGPS link={link} res={res} /></Route>

                    <Route path="/app/clima"><Climate link={link} res={res} /></Route>
                    <Route path="/app/cadastro/estacao-meteorologica/list"><ClimateList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/estacao-meteorologica/add"><ClimateEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/estacao-meteorologica/edit/:id"><ClimateEdit link={link} res={res} /></Route>

                    <Route path="/app/custo"><Cost link={link} res={res} /></Route>

                    <Route path="/app/stats/:guid"><Stats link={link} res={res} /></Route>
                    <Route path="/app/stats"><Stats link={link} res={res} /></Route>
                    <Route path="/app/kmz-map/:guid"><KMZMap link={link} res={res} /></Route>
                    {/* <Route path="/app/analise"><Analyze link={link} res={res} /></Route> */}

                    <Route path="/app/avaliacao/:guid"><Appraisal link={link} res={res} /></Route>
                    <Route path="/app/avaliacao"><Appraisal link={link} res={res} /></Route>

                    {/* <Route path="/app/tradutor"><Translate link={link} res={res} /></Route> */}
                    <Route path="/app/relatorio/:guid"><Reports link={link} res={res} /></Route>
                    <Route path="/app/relatorio"><Reports link={link} res={res} /></Route>

                    <Route path="/app/admin/saldo"><AdminBalance link={link} res={res} /></Route>

                    <Route path="/app/teste"><Teste link={link} res={res} /></Route>
                    <Route path="/app/dashboard"><Dashboard link={link} res={res} /></Route>
                    <Route path="/app/calibracao"><Calibracao link={link} res={res} /></Route>

                    <Route path="/app/cadastro/agronomo/list"><AgronomistList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/agronomo/add"><AgronomistEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/agronomo/edit/:id"><AgronomistEdit link={link} res={res} /></Route>

                    <Route path="/app/cadastro/tecnico/list"><TechnicianList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/tecnico/add"><TechnicianEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/tecnico/edit/:id"><TechnicianEdit link={link} res={res} /></Route>

                    <Route path="/app/cadastro/piloto/list"><PilotList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/piloto/add"><PilotEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/piloto/edit/:id"><PilotEdit link={link} res={res} /></Route>

                    <Route path="/app/cadastro/cliente/list"><ClientList link={link} res={res} /></Route>
                    <Route path="/app/cadastro/cliente/add"><ClientEdit link={link} res={res} /></Route>
                    <Route path="/app/cadastro/cliente/edit/:id"><ClientEdit link={link} res={res} /></Route>

                    <Route path="/app/painel-qualidade"><QualityDashboard link={link} res={res} /></Route>

                    <Route path="/app/relatorio-mapa/list"><ReportsMAPA link={link} res={res} /></Route>
                    <Route path="/app/relatorio-mapa/edit/:id"><EditReportsMAPA link={link} res={res} /></Route>

                </Switch>

            </Router>
        )
    }
}

export default Content;