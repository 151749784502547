import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import MaterialIcon from './../../components/MaterialIcon';
import MenuList from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Config from '../../constants/appConfig';

import Button from '@material-ui/core/Button';

import Tab from '../../components/Tab/tab';

import pt from './../../assets/images/flag/16/Brazil.png';
import en from './../../assets/images/flag/16/United-States.png';


class header extends React.Component {
    state = {
        redirect: false,
        anchorEl: null,
        name: '',
        language: this.props.res.LINGUA,
        listAlert: [],
        qtdAlert: 0,
        anchorElAlert: null,
        tab: '0',
        showClose: null,
        tabItens: [
            { label: 'Todos', value: '0', qtd: 0 },//this.props.res.LOCALATUAL
            { label: 'Avisos', value: 'aviso', qtd: 0 },
            { label: 'Relatórios', value: 'relatorio', qtd: 0 },
            /*{ label: 'Transferencias', value: 'transferencia', qtd: 0 },*/
            /*{ label: 'Trabalhos', value: 'job', qtd: 0 },*/
            { label: 'Aeronaves', value: 'DGPS', qtd: 0 },
        ]
    };

    componentDidMount() {
        const apiUrl = Config.server + '/currentUser';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200) throw new Error(res.status);
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        this.checkAlert();
                        this.setState({ name: result[0].name });
                        if (result[0].language) {
                            this.setState({ language: result[0].language });
                            this.props.lan(result[0].language);
                        }
                    }
                },
                (error) => {
                    this.props.link('/login');
                }
            ).catch((e) => {
                this.props.link('/login');
            })
    }


    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    first = true;
    checkAlert = () => {
        const apiUrl = Config.server + '/alert';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200) throw new Error(res.status);
                else return res.json();
            })
            .then((result) => {
                if (Array.isArray(result) && result.length > 0) {
                    let tabItens = this.state.tabItens;

                    tabItens[0].qtd = 0;
                    tabItens[1].qtd = 0;
                    tabItens[2].qtd = 0;
                    tabItens[3].qtd = 0;

                    for (let i = 0; i < result.length; i++) {
                        tabItens[0].qtd++;
                        if (result[i].type === tabItens[1].value) {
                            tabItens[1].qtd++;
                        } else if (result[i].type === tabItens[2].value) {
                            tabItens[2].qtd++;
                        } else if (result[i].type === tabItens[3].value ||
                            result[i].type === 'transferencia' ||
                            result[i].type === 'job') {
                            tabItens[3].qtd++;
                        }
                    }

                    const qtd = result.filter(item => (item.has_seen === 0));

                    this.setState({ listAlert: result, qtdAlert: qtd.length, tabItens });
                }
                if (this.first) {
                    this.first = false;
                    setTimeout(() => {
                        this.checkAlert();
                    }, 120000);
                }
            }, (error) => {

            })
    }

    updateSeenAlert = () => {
        const list = this.state.listAlert.filter(item => (item.has_seen === 0)).map(item => (item.id));
        if (list.length > 0) {
            this.updateAlert('seen', JSON.stringify(list));
        }
    };

    updateAlert = (type, ids) => {
        const apiUrl = Config.server + '/alert';

        const formData = new URLSearchParams();
        formData.append('type', type);
        formData.append('ids', ids);

        let obj = {
            method: 'PUT',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200) throw new Error(res.status);
                else return res.json();
            })
            .then((result) => {
                if (type === 'read') {
                    this.checkAlert();
                } else {
                    this.setState({ qtdAlert: 0 });
                }
            }, (error) => { })
    };

    handleClick = name => event => {
        this.setState({ [name]: event.currentTarget }, () => {
            this.updateSeenAlert();
        });
    };
    handleClose = name => () => {
        this.setState({ [name]: null, showClose: null });
    };

    handleChange = event => {
        this.props.lan(event.target.value);
    };

    close = false;
    goAlert = (item) => {
        if (!this.close) {
            if (item.callback) {
                this.props.link('/app/link');
                setTimeout(() => {
                    this.props.link('/app' + item.callback);
                }, 10);
                this.handleClose('anchorElAlert')();
            }
        } else {
            this.close = false;
        }
    };

    goPerfil = () => {
        this.props.link('/app/perfil');
    };

    goExtrato = () => {
        this.props.link('/app/extrato');
    };

    handleChangeTab = name => (event, newValue) => {
        this.setState({
            [name]: newValue,
        });
    };

    showCondition = (item) => {
        const tabItens = this.state.tabItens;
        let condition = true;
        if (this.state.tab !== '0') {
            if (this.state.tab === tabItens[1].value) {
                if (item.type !== tabItens[1].value) {
                    condition = false;
                }
            } else if (this.state.tab === tabItens[2].value) {
                if (item.type !== tabItens[2].value) {
                    condition = false;
                }
            } else if (this.state.tab === tabItens[3].value ||
                this.state.tab === 'transferencia' ||
                this.state.tab === 'job') {
                if (item.type !== tabItens[3].value &&
                    item.type !== 'transferencia' &&
                    item.type !== 'job') {
                    condition = false;
                }
            }
        }
        return condition;
    };

    formatTime = (date) => {
        let newDate = new Date();
        let diff = newDate - date;
        let msg;
        if (diff < 60000) {
            msg = Math.floor(diff / 1000) + ' seg'
        } else if (diff < 3600000) {
            msg = Math.floor(diff / 60000) + ' min'
        } else if (diff < 86400000) {
            msg = Math.floor(diff / 3600000) + ' horas'
        } else {
            msg = Math.floor(diff / 86400000) + ' dias'
        }
        return msg;
    };

    clickClose = (id) => {
        this.close = true;
        if (id) {
            this.updateAlert('read', JSON.stringify([id]));
        }
    };

    render() {
        const { anchorEl } = this.state;
        const showMenu = this.props.menu;
        return (
            <div className="header">
                <div className="header-container">
                    <div className="left-header hideS">
                        <Button className="menu_button" onClick={event => showMenu()}>
                            <MaterialIcon icon="menu" className="menu_icon" />
                        </Button>
                    </div>
                    <div className="header-center"></div>
                    <div className="header-right">
                        <div style={{ marginRight: '2rem' }}>
                            <Select
                                value={this.state.language}
                                onChange={this.handleChange}
                                inputProps={{
                                    id: 'language',
                                }}
                            >
                                <MenuItem value="pt-BR">
                                    <img src={pt} alt="Logo" /> &nbsp;Português
                                </MenuItem>
                                <MenuItem value="en-US">
                                    <img src={en} alt="Logo" /> &nbsp;English
                                </MenuItem>

                            </Select>
                        </div>
                        <div>
                            <IconButton onClick={this.handleClick('anchorElAlert')}>
                                {this.state.qtdAlert > 0 && <div style={{
                                    position: 'absolute', backgroundColor: 'red', height: '1rem', left: '1.5rem', top: '.4rem', borderRadius: '.5rem',
                                    font: 'caption', fontSize: '.7rem', color: 'white', lineHeight: '1rem', padding: '0 5px 0 4px', fontWeight: 'bold'
                                }}>
                                    {this.state.qtdAlert}
                                </div>}
                                <NotificationsIcon color="action" />
                            </IconButton>
                            <MenuList
                                id="app-header-alert"
                                className="popup-list"
                                anchorEl={this.state.anchorElAlert}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={Boolean(this.state.anchorElAlert)}
                                onClose={this.handleClose('anchorElAlert')}
                            >
                                <div style={{ padding: '0px 0.5rem', margin: '-.6rem 0 -2.5rem', display: 'flex' }}>
                                    <Tab value={this.state.tab} handleChange={this.handleChangeTab} name="tab" itens={this.state.tabItens.map(item => ({ value: item.value, label: `${item.label} (${item.qtd})` }))} />
                                </div>
                                <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 10rem)' }}>
                                    {this.state.listAlert.length > 0 ?
                                        this.state.listAlert
                                            .filter(this.showCondition)
                                            .map(it => (
                                                <MenuItem style={{ width: '27rem', borderBottom: 'solid 1px #f1f1f1' }}                                                    
                                                    onMouseOver={() => { this.setState({ showClose: it.id }) }}
                                                    onClick={(event) => { this.goAlert(it); }} className="popup-item">
                                                    <div style={{ width: '100%', textAlign: 'left' }}>
                                                        <div style={{ fontWeight: 'bold' }}>{it.title}</div>
                                                        <div style={{ whiteSpace: 'normal', paddingLeft: '.4rem' }}>{it.text}</div>
                                                        <div style={{ textAlign: 'right', paddingRight: '.4rem', color: '#b3b3b3', fontSize: '13px' }}>{this.formatTime(new Date(it.created))}</div>
                                                        {this.state.showClose === it.id &&
                                                            <div onClick={() => { this.clickClose(it.id) }} style={{ position: 'absolute', right: '10px', top: '5px', width: '2rem', height: '2rem', opacity: '.4' }}>
                                                                <MaterialIcon style={{ margin: '-8px 0 0 -3px' }} icon="close" className="menu_icon" />
                                                            </div>
                                                        }
                                                    </div>
                                                </MenuItem>
                                            )) : null}
                                    <div style={{ width: '27rem' }}></div>
                                </div>
                            </MenuList>
                        </div>
                        <div>
                            <Button onClick={this.handleClick('anchorEl')}>{this.state.name}</Button>
                            <MenuList
                                id="app-header-menu"
                                className="popup-list"
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose('anchorEl')}
                            >
                                {/* <MenuItem onClick={(event) => {this.goPerfil(); this.handleClose();}} className="popup-item"> <div><strong>{this.props.res.LIMITES}</strong></div> </MenuItem>
                                <div className="divider"></div> */}
                                <MenuItem onClick={(event) => { this.goExtrato(); this.handleClose('anchorEl'); }} className="popup-item"> <div><strong>{this.props.res.EXTRATO}</strong></div> </MenuItem>
                                <div style={{ padding: '.2rem 0' }}></div>
                                <MenuItem onClick={this.handleClose('anchorEl')} className="popup-item">
                                    <a href='/login' className="linha-pop" style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                        <span>{this.props.res.SAIR}</span>
                                        <MaterialIcon icon="forward" style={{ marginLeft: '.3rem' }} />
                                    </a>
                                </MenuItem>
                            </MenuList>

                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

export default header;