import React from 'react';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

class DiaryOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: props.jobs || [],
        };
    }

    componentDidMount() { }

    render() {

        return (
            <div className='dashboard-card'>
                <div className='divContent'>
                    <div className='cardheader'>
                        <TrendingUpIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className='title'>
                            OPERAÇÕES
                        </span>
                    </div>
                    <div className='cardmiddle' style={{ flexDirection: 'column' }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default DiaryOperation;