import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useParams } from "react-router";

import asaEsquerda from '../../../assets/images/AirTractor-AsaEsquerda.png';
import asaDireita from '../../../assets/images/AirTractor-AsaDireita.png';
import barriga from '../../../assets/images/AirTractor-Barriga.png';

import Config from '../../../constants/appConfig';
import MaterialIcon from './../../../components/MaterialIcon';
import { notification } from 'antd';

import './style.css';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    primary: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    secondary: {
        backgroundColor: '#e0e0e0',
    },
});

class AircraftConfiguration extends React.Component {
    state = {
        id: this.props.id,
        id_equipment: this.props.id_equipment,
        id_configuration: '',
        typeEquipment: '',
        equipmentData: {},
        aircraftItem: '',
        nozzlePosition: '',
        atomizerPosition: '',
        quantityNozzle: 0,
        quantityAtomizer: 0,
        quantityNozzleRegistered: 0,
        quantityAtomizerRegistered: 0,
        nozzleLeft: 0,
        nozzleCenterLeft: 0,
        nozzleCenterRight: 0,
        nozzleRight: 0,
        atomizerLeft: 0,
        atomizerCenterLeft: 0,
        atomizerCenterRight: 0,
        atomizerRight: 0,
        selectedPositionsNozzle: {
            asaEsquerda: [],
            barrigaEsquerda: [],
            barrigaDireita: [],
            asaDireita: [],
        },
        selectedPositionsAtomizer: {
            asaEsquerda: {
                position: [],
                inputFields: {},
            },
            asaDireita: {
                position: [],
                inputFields: {},
            },
            barrigaEsquerda: {
                position: [],
                inputFields: {},
            },
            barrigaDireita: {
                position: [],
                inputFields: {},
            },
        },
        holes: 0,
        calibration: '',
    };

    componentDidMount() {
        this.readEquipment();
        this.readConfiguration();
        this.readCalibration();
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    handleChangeItemNozzle = (newAircraftItem) => {
        this.setState((prevState) => ({
            aircraftItem: newAircraftItem,
            selectedPositionsNozzle: {
                ...prevState.selectedPositionsNozzle,
                [newAircraftItem]: prevState.selectedPositionsNozzle[newAircraftItem] || [],
            },
        }));
    };

    handleChangeItemAtomizer = (newAircraftItem) => {
        this.setState({
            aircraftItem: newAircraftItem,
        });
    };

    handleChangeItem = (newAircraftItem) => {
        const { typeEquipment } = this.state;

        if (typeEquipment === 'Bico Hidráulico') {
            this.handleChangeItemNozzle(newAircraftItem);
        } else if (typeEquipment === 'Atomizador') {
            this.handleChangeItemAtomizer(newAircraftItem)
        }
    }

    togglePositionNozzle = (index) => {
        const { selectedPositionsNozzle, aircraftItem, quantityNozzleRegistered } = this.state;
        const currentSelectedPositions = selectedPositionsNozzle[aircraftItem] || [];
        const totalSelectedNozzles = Object.values(selectedPositionsNozzle)
            .reduce((total, positions) => total + positions.length, 0);

        if (currentSelectedPositions.includes(index)) {
            this.setState((prevState) => ({
                selectedPositionsNozzle: {
                    ...prevState.selectedPositionsNozzle,
                    [aircraftItem]: currentSelectedPositions.filter((pos) => pos !== index),
                },
            }));
        } else {
            if (totalSelectedNozzles < quantityNozzleRegistered) {
                this.setState((prevState) => ({
                    selectedPositionsNozzle: {
                        ...prevState.selectedPositionsNozzle,
                        [aircraftItem]: [...currentSelectedPositions, index].sort((a, b) => a - b),
                    },
                }));
            } else {
                notification.open({
                    message: this.props.res.ATENCAO,
                    description: `${this.props.res.ERRO_QUANTIDADE_POSICOES_BICO} ${quantityNozzleRegistered}.`,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
            }
        }
    };

    togglePositionAtomizer = (index) => {
        const { aircraftItem, selectedPositionsAtomizer, quantityAtomizerRegistered } = this.state;
        let currentSelectedPositions = selectedPositionsAtomizer[aircraftItem].position || [];
        let totalSelectedPositions = 0;

        Object.values(selectedPositionsAtomizer).forEach(item => {
            totalSelectedPositions += (item.position || []).length;
        });

        if (currentSelectedPositions.includes(index)) {
            currentSelectedPositions = currentSelectedPositions.filter(pos => pos < index);
            totalSelectedPositions -= (currentSelectedPositions.length - totalSelectedPositions);
        } else {
            if (totalSelectedPositions >= quantityAtomizerRegistered) {
                notification.open({
                    message: this.props.res.ATENCAO,
                    description: `${this.props.res.ERRO_QUANTIDADE_POSICOES_ATOMIZADOR} ${quantityAtomizerRegistered}.`,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
                return;
            }
            if (index === 1 || currentSelectedPositions.includes(index - 1)) {
                currentSelectedPositions.push(index);
            } else {
                notification.open({
                    message: this.props.res.ATENCAO,
                    description: this.props.res.ERRO_ORDEM,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
                return;
            }
        }

        this.setState(prevState => ({
            selectedPositionsAtomizer: {
                ...prevState.selectedPositionsAtomizer,
                [aircraftItem]: {
                    ...prevState.selectedPositionsAtomizer[aircraftItem],
                    position: currentSelectedPositions.sort((a, b) => a - b),
                },
            },
        }));
    };

    addNozzlePosition = (quantityNozzle) => {
        const { res } = this.props;
        const { selectedPositionsNozzle, aircraftItem } = this.state;

        if (quantityNozzle <= 0) {
            return null;
        }

        const nozzlePosition = Array.from({ length: quantityNozzle }, (_, index) => (
            <div
                key={index}
                className={aircraftItem === 'asaEsquerda' || aircraftItem === 'barrigaEsquerda'
                    ? 'bodyCheckbox' : 'bodyCheckboxRightWing'}>
                <label className="customCheckbox">
                    <input
                        type="checkbox"
                        value={index + 1}
                        checked={selectedPositionsNozzle[aircraftItem]?.includes(index + 1)}
                        onChange={() => this.togglePositionNozzle(index + 1)}
                        className="customInputCheckbox"
                    />
                    <span className="checkboxNumber">{index + 1}</span>
                </label>
            </div>
        ));

        const nozzlePositionGroups = [];
        for (let i = 0; i < nozzlePosition.length; i += (aircraftItem.substring(0, 3) === 'asa' ? 50 : 8)) {
            let divClassName;
            let sliceSize;

            switch (aircraftItem) {
                case 'asaEsquerda':
                    divClassName = 'ContainerLeftWing';
                    sliceSize = 50;
                    break;
                case 'barrigaEsquerda':
                    divClassName = 'ContainerPlaneCenterLeft';
                    sliceSize = 8;
                    break;
                case 'barrigaDireita':
                    divClassName = 'ContainerPlaneCenterRight';
                    sliceSize = 8;
                    break;
                case 'asaDireita':
                    divClassName = 'ContainerRightWing';
                    sliceSize = 50;
                    break;
                default:
                    return '';
            }

            nozzlePositionGroups.push(
                <div key={i} className={divClassName}>
                    {nozzlePosition.slice(i, i + sliceSize)}
                </div>
            );
        }

        return (
            <div className="bodyContainerImg">
                <span className="titlePositionItem">{res.POSICOES_BICOS}</span>
                {nozzlePositionGroups}
                {
                    aircraftItem === 'asaDireita'
                        ?
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img
                                className="planeWingImg"
                                src={asaDireita}
                                alt="Asa Direita imagem"
                                style={{
                                    width: `${this.state.nozzleRight * 2.25}rem`,
                                }}
                            />
                        </div>
                        :
                        aircraftItem === 'asaEsquerda'
                            ?
                            <img
                                className="planeWingImg"
                                src={asaEsquerda}
                                alt="Asa Esquerda imagem"
                                style={{
                                    width: `${this.state.nozzleLeft * 2.25}rem`,
                                }} />
                            :
                            <img className="planeCenterImg" src={barriga} alt="Barriga imagem" />
                }
            </div>
        );
    };

    generateRuler = () => {
        const { selectedPositionsAtomizer, aircraftItem } = this.state;
        const selectedValues = selectedPositionsAtomizer[aircraftItem].position || [];

        const rulerSegments = selectedValues.map((position, index) => {
            const distance = selectedPositionsAtomizer[aircraftItem]?.inputFields[position] || 0;
            const segmentWidth = index !== 0 ? "8.25rem" : aircraftItem === "asaDireita" ? "12.2rem" : "11.75rem";

            return (
                <div key={index} className="rulerSegment" style={{ width: segmentWidth }}>
                    <div className="rulerMark"></div>
                    <span className={aircraftItem === 'asaDireita' ? "rulerDistanceRight" : "rulerDistance"}>{distance} cm</span>
                </div>
            );
        });

        const additionalClass = aircraftItem === "asaDireita" ? "rulerRight" : "";
        return <div className={`rulerContainer ${additionalClass}`}>{rulerSegments}</div>;
    }

    addAtomizerPosition = (quantityAtomizer) => {
        const { res } = this.props;
        const { aircraftItem, selectedPositionsAtomizer, inputFields } = this.state;
        const selectedValues = selectedPositionsAtomizer[aircraftItem].position || [];
        selectedValues.sort((a, b) => a - b);

        let classMarginPosition;

        switch (aircraftItem) {
            case 'asaEsquerda':
                classMarginPosition = 'bodyCheckboxAtomizer';
                break;
            case 'barrigaEsquerda':
                classMarginPosition = 'bodyCheckbox';
                break;
            case 'barrigaDireita':
                classMarginPosition = 'bodyCheckboxRightWing';
                break;
            case 'asaDireita':
                classMarginPosition = 'bodyCheckboxAtomizerRightWing';
                break;
            default:
                classMarginPosition = '';
        }

        const atomizerPosition = Array.from({ length: quantityAtomizer }, (_, index) => (
            <div key={index} className={classMarginPosition}>
                <label className="customCheckbox">
                    <input
                        type="checkbox"
                        value={index + 1}
                        checked={selectedValues.includes(index + 1)}
                        onChange={() => this.togglePositionAtomizer(index + 1)}
                        className="customInputCheckbox"
                    />
                    <span className="checkboxNumber">{index + 1}</span>
                </label>
            </div>
        ));

        const selectedInputFields = selectedValues.map((position) => (
            <div key={position}>
                <FormControl className="containerInputPositionAtomizer">
                    <TextField
                        id={`atomizer${position}`}
                        label={position === 1 ? `Atomizador ${position} (Distância do centro da aeronave) ` : `Atomizador ${position} (Distância entre o atomizador ${position - 1} e o ${position}) `}
                        type="number"
                        value={selectedPositionsAtomizer[aircraftItem]?.inputFields[position] || 0}
                        onChange={(e) => this.handleInputFieldChange(position, e.target.value)}
                        className="inputPositionAtomizer"
                        InputProps={{
                            inputProps: {
                                min: 0,
                            },
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        style={{ margin: '0.5rem 0', whiteSpace: 'nowrap', width: '8rem' }}
                        required
                    />
                </FormControl >
            </div >
        ));

        const atomizerPositionGroups = [];
        for (let i = 0; i < atomizerPosition.length; i += (aircraftItem.substring(0, 3) === 'asa' ? 10 : 8)) {
            let divClassName;
            let sliceSize;

            switch (aircraftItem) {
                case 'asaEsquerda':
                    divClassName = 'ContainerLeftWing';
                    sliceSize = 10;
                    break;
                case 'barrigaEsquerda':
                    divClassName = 'ContainerPlaneCenterLeft';
                    sliceSize = 8;
                    break;
                case 'barrigaDireita':
                    divClassName = 'ContainerPlaneCenterRight';
                    sliceSize = 8;
                    break;
                case 'asaDireita':
                    divClassName = 'ContainerRightWing';
                    sliceSize = 10;
                    break;
                default:
                    return '';
            }

            atomizerPositionGroups.push(
                <div key={i} className={divClassName}>
                    {atomizerPosition.slice(i, i + sliceSize)}
                </div>
            );
        }

        return (
            <div className="bodyContainerImg">
                <span className="titlePositionItem">{res.POSICOES_ATOMIZADORES}</span>
                {aircraftItem.substring(0, 3) === 'asa' ? this.generateRuler() : ''}
                {atomizerPositionGroups}
                {aircraftItem === 'asaDireita'
                    ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img
                            className="planeWingImg"
                            src={asaDireita}
                            alt="Asa Direita imagem"
                            style={{
                                width: `${this.state.atomizerRight * 10}rem`,
                            }}
                        />
                    </div>
                    :
                    aircraftItem === 'asaEsquerda'
                        ?
                        <img
                            className="planeWingImg"
                            src={asaEsquerda}
                            alt="Asa Esquerda imagem"
                            style={{
                                width: `${this.state.atomizerLeft * 10}rem`,
                            }}
                        />
                        :
                        <img className="planeCenterImg" src={barriga} alt="Barriga imagem" />
                }
                {selectedPositionsAtomizer[aircraftItem]?.position?.length > 0 && (
                    <div style={{ marginTop: '2rem' }}>
                        <InputLabel htmlFor="type" style={{ marginBottom: '0.5rem' }}>{res.DISTANCIA_ATOMIZADOR}</InputLabel>
                        {selectedInputFields}
                    </div>
                )}
            </div>
        );
    };

    handleInputFieldChange = (position, value) => {
        const { aircraftItem } = this.state;

        this.setState((prevState) => {
            const selectedPositionsAtomizer = { ...prevState.selectedPositionsAtomizer };
            const selectedPosition = { ...selectedPositionsAtomizer[aircraftItem] };
            selectedPosition.inputFields = {
                ...selectedPosition.inputFields,
                [position]: value,
            };
            selectedPositionsAtomizer[aircraftItem] = selectedPosition;
            return { selectedPositionsAtomizer };
        });
    };

    switchNozzleConfigurtation = () => {
        const { res } = this.props;
        const { aircraftItem, nozzleLeft, nozzleCenterLeft, nozzleCenterRight, nozzleRight } = this.state;

        switch (aircraftItem) {
            case 'asaEsquerda':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="nozzleLeft">{res.QUANTIDADE_BICOS} {res.ASA_ESQUERDA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="nozzleLeft"
                                    value={nozzleLeft}
                                    className="inputCounterItems"
                                    onChange={this.handleChangeNumNozzle}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addNozzlePosition(nozzleLeft)}
                    </div>
                )
            case 'barrigaEsquerda':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="nozzleCenterLeft">{res.QUANTIDADE_BICOS} {res.BARRIGA_ESQUERDA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="nozzleCenterLeft"
                                    value={nozzleCenterLeft}
                                    onChange={this.handleChangeNumNozzle}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addNozzlePosition(nozzleCenterLeft)}
                    </div>
                )
            case 'barrigaDireita':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="nozzleCenterRight">{res.QUANTIDADE_BICOS} {res.BARRIGA_DIREITA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="nozzleCenterRight"
                                    value={nozzleCenterRight}
                                    onChange={this.handleChangeNumNozzle}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addNozzlePosition(nozzleCenterRight)}
                    </div>
                )
            case 'asaDireita':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="nozzleRight">{res.QUANTIDADE_BICOS} {res.ASA_DIREITA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="nozzleRight"
                                    value={nozzleRight}
                                    className="inputCounterItems"
                                    onChange={this.handleChangeNumNozzle}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addNozzlePosition(nozzleRight)}
                    </div>
                )
            default:
                return ''
        }
    }

    switchAtomizerConfigurtation = () => {
        const { res } = this.props;
        const { aircraftItem, quantityAtomizerRegistered, atomizerLeft, atomizerCenterLeft, atomizerCenterRight, atomizerRight } = this.state;

        switch (aircraftItem) {
            case 'asaEsquerda':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="atomizerLeft">{res.QUANTIDADE_ATOMIZADORES} {res.ASA_ESQUERDA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="atomizerLeft"
                                    value={atomizerLeft}
                                    onChange={this.handleChangeNumAtomizer}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addAtomizerPosition(atomizerLeft)}
                    </div>
                )
            case 'barrigaEsquerda':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="atomizerCenterLeft">{res.QUANTIDADE_ATOMIZADORES} {res.BARRIGA_ESQUERDA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="atomizerCenterLeft"
                                    value={atomizerCenterLeft}
                                    onChange={this.handleChangeNumAtomizer}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addAtomizerPosition(atomizerCenterLeft)}
                    </div>
                )
            case 'barrigaDireita':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="atomizerCenterRight">{res.QUANTIDADE_ATOMIZADORES} {res.BARRIGA_DIREITA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="atomizerCenterRight"
                                    value={atomizerCenterRight}
                                    onChange={this.handleChangeNumAtomizer}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addAtomizerPosition(atomizerCenterRight)}
                    </div>
                )
            case 'asaDireita':
                return (
                    <div className="alignCounterItems">
                        <div>
                            <InputLabel htmlFor="atomizerRight">{res.QUANTIDADE_ATOMIZADORES} {res.ASA_DIREITA}</InputLabel>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    type="number"
                                    id="atomizerRight"
                                    value={atomizerRight}
                                    onChange={this.handleChangeNumAtomizer}
                                    className="inputCounterItems"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.addAtomizerPosition(atomizerRight)}
                    </div>
                )
            default:
                return ''
        }
    }

    handleChangeNumNozzle = (event) => {
        const { aircraftItem } = this.state;

        const quantityNozzle = event.target.value;

        if (aircraftItem === 'asaEsquerda') {
            this.setState({ nozzleLeft: quantityNozzle });
        } else if (aircraftItem === 'barrigaEsquerda') {
            this.setState({ nozzleCenterLeft: quantityNozzle });
        } else if (aircraftItem === 'barrigaDireita') {
            this.setState({ nozzleCenterRight: quantityNozzle });
        } else if (aircraftItem === 'asaDireita') {
            this.setState({ nozzleRight: quantityNozzle });
        }
    };

    handleChangeNumAtomizer = (event) => {
        const { aircraftItem } = this.state;
        const quantityAtomizer = event.target.value;

        if (aircraftItem === 'asaEsquerda') {
            this.setState({ atomizerLeft: quantityAtomizer });
        } else if (aircraftItem === 'barrigaEsquerda') {
            this.setState({ atomizerCenterLeft: quantityAtomizer });
        } else if (aircraftItem === 'barrigaDireita') {
            this.setState({ atomizerCenterRight: quantityAtomizer });
        } else if (aircraftItem === 'asaDireita') {
            this.setState({ atomizerRight: quantityAtomizer });
        }
    };

    readEquipment = () => {
        const { id_equipment } = this.state;

        const apiUrl = Config.server + `/plane_equipment/${id_equipment}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        let data;
                        const equipmentPlane = result.map((equipments) => {
                            return data = equipments;
                        })
                        this.setState({
                            equipmentData: data,
                            quantityNozzleRegistered: data?.quantityNozzle,
                            quantityAtomizerRegistered: data?.quantityAtomizer,
                            typeEquipment: data.equipmentType,
                            holes: data?.holes,
                            nozzleLeft: data?.holes,
                            nozzleRight: data?.holes,
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    readConfiguration = () => {
        const { id_equipment } = this.state;

        const apiUrl = Config.server + `/plane_equipment_configuration/${id_equipment}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        let configuration;
                        let position;
                        const configurationData = result.map((equipments) => {
                            configuration = equipments;
                            if (configuration.quantityNozzle > 0) {
                                position = JSON.parse(equipments.nozzlePosition);
                            } else if (configuration.quantityAtomizer > 0) {
                                position = JSON.parse(equipments.atomizerPosition);
                            }
                        });

                        if (configuration.quantityNozzle > 0) {
                            const lastElementCenterLeft = position.barrigaEsquerda.length > 0 ? position.barrigaEsquerda.sort(function (a, b) { return a - b })[position?.barrigaEsquerda.length - 1] : 0;
                            const lastElementCenterRight = position.barrigaDireita.length > 0 ? position.barrigaDireita.sort(function (a, b) { return a - b })[position?.barrigaDireita.length - 1] : 0;
                            this.setState({
                                nozzleLeft: this.state.holes,
                                nozzleCenterLeft: lastElementCenterLeft !== 0 ? lastElementCenterLeft : 0,
                                nozzleCenterRight: lastElementCenterRight !== 0 ? lastElementCenterRight : 0,
                                nozzleRight: this.state.holes,
                            })
                        } else if (configuration.quantityAtomizer > 0) {
                            const lastElementLeftWing = position.asaEsquerda.position.length > 0 ? position.asaEsquerda.position.sort(function (a, b) { return a - b })[position.asaEsquerda.position.length - 1] : 0;
                            const lastElementCenterLeft = position.barrigaEsquerda.position.length > 0 ? position.barrigaEsquerda.position.sort(function (a, b) { return a - b })[position.barrigaEsquerda.position.length - 1] : 0;
                            const lastElementCenterRight = position.barrigaDireita.position.length > 0 ? position.barrigaDireita.position.sort(function (a, b) { return a - b })[position.barrigaDireita.position.length - 1] : 0;
                            const lastElementRightWing = position.asaDireita.position.length > 0 ? position.asaDireita.position.sort(function (a, b) { return a - b })[position.asaDireita.position.length - 1] : 0;
                            this.setState({
                                atomizerLeft: lastElementLeftWing !== 0 ? lastElementLeftWing : 0,
                                atomizerCenterLeft: lastElementCenterLeft !== 0 ? lastElementCenterLeft : 0,
                                atomizerCenterRight: lastElementCenterRight !== 0 ? lastElementCenterRight : 0,
                                atomizerRight: lastElementRightWing !== 0 ? lastElementRightWing : 0,
                            });
                        }

                        this.setState({
                            id_configuration: configuration.id,
                            nozzlePosition: configuration?.nozzlePosition,
                            atomizerPosition: configuration?.atomizerPosition,
                            selectedPositionsNozzle: position,
                            selectedPositionsAtomizer: position,
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    readCalibration = () => {
        const { id_equipment } = this.state;

        const apiUrl = Config.server + `/plane_equipment_calibration/${id_equipment}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    this.setState({
                        calibration: result,
                    });
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    registerConfiguration = () => {
        const { link } = this.props;
        const { id_configuration, id_equipment, calibration, selectedPositionsAtomizer, selectedPositionsNozzle, id, typeEquipment,
            quantityNozzleRegistered, quantityAtomizerRegistered } = this.state;

        let apiUrl = '';
        let method = '';
        apiUrl = Config.server + '/plane_equipment_configuration';

        const formData = new URLSearchParams();
        if (id_configuration && id_equipment) {
            apiUrl += `/${id_configuration}`;
            formData.append('id', id_configuration);
            method = 'PUT';
        } else {
            method = 'POST';
        }

        formData.append('id_plane', id);
        formData.append('id_equipment', id_equipment);
        if (typeEquipment === 'Bico Hidráulico') {
            const selectedPositionsNozzleAsString = JSON.stringify(selectedPositionsNozzle);
            formData.append('nozzlePosition', selectedPositionsNozzleAsString);
            formData.append('quantityNozzle', quantityNozzleRegistered);
        } else if (typeEquipment === 'Atomizador') {
            const selectedPositionsAtomizerAsString = JSON.stringify(selectedPositionsAtomizer);
            formData.append('atomizerPosition', selectedPositionsAtomizerAsString);
            formData.append('quantityAtomizer', quantityAtomizerRegistered);
        }

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else {
                        let msg = this.props.res.ITEM_INCLUIR;
                        if (id) {
                            msg = this.props.res.ITEM_ALTERADO;
                        }
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: msg,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                    }
                    { calibration.length === 0 ? link(`/app/cadastro/aeronave/${id}/calibracao/${id_equipment}`) : link(`/app/cadastro/aeronave/edit/${id}`) }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG_INFORMACOES,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    }

    registerConfigurationEquipment = () => {
        const { typeEquipment, selectedPositionsNozzle, selectedPositionsAtomizer, quantityNozzleRegistered } = this.state;

        if (typeEquipment === 'Bico Hidráulico') {
            let tamanhoTotalPositionNozzle = 0;

            for (const item in selectedPositionsNozzle) {
                if (Array.isArray(selectedPositionsNozzle[item])) {
                    tamanhoTotalPositionNozzle += selectedPositionsNozzle[item].length;
                }
            }

            if (tamanhoTotalPositionNozzle == quantityNozzleRegistered) {
                this.registerConfiguration()
            } else {
                notification.open({
                    message: this.props.res.ERRO,
                    description: this.props.res.ERRO_POSICAO_BICO,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
            }
        } else if (typeEquipment === 'Atomizador') {
            for (const part in selectedPositionsAtomizer) {
                if (selectedPositionsAtomizer[part]) {
                    const currentPart = selectedPositionsAtomizer[part];
                    for (let pos in currentPart.inputFields) {
                        if (!currentPart.position.includes(parseInt(pos))) {
                            delete currentPart.inputFields[pos];
                        }
                    }
                }
            }

            let totalPositionAtomizer = 0;
            let totalInputsAtomizer = 0;

            for (const item in selectedPositionsAtomizer) {
                if (selectedPositionsAtomizer[item]) {
                    totalPositionAtomizer += selectedPositionsAtomizer[item]?.position.length;
                    totalInputsAtomizer += Object.keys(selectedPositionsAtomizer[item]?.inputFields).length;
                }
            }

            if (totalPositionAtomizer === totalInputsAtomizer) {
                this.registerConfiguration()
            } else {
                notification.open({
                    message: this.props.res.ERRO,
                    description: this.props.res.ERRO_POSICAO_ATOMIZADOR,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
            }
        }
    }

    calculateTotalSelectedPositions = () => {
        const { selectedPositionsAtomizer } = this.state;

        let total = 0;

        Object.values(selectedPositionsAtomizer).forEach((item) => {
            total += item.position.length;
        });

        return total;
    };

    render() {
        const { link, res, classes } = this.props;
        const { id, aircraftItem, typeEquipment, quantityAtomizerRegistered, quantityNozzleRegistered, selectedPositionsNozzle, selectedPositionsAtomizer } = this.state;

        return (
            <div>
                <div className="containerTop">
                    <div><h1>{res.CONFIGURACAO_AERONAVE}</h1></div>
                    <div className="centerTop"></div>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => (link(`/app/cadastro/aeronave/edit/${id}`))}
                            className="popUpButton"
                        >
                            {res.CANCELAR}
                        </Button> &nbsp;
                        <Button type="submit" variant="contained" color="primary" className="popUpButton" onClick={this.registerConfigurationEquipment}>
                            <SaveIcon className="iconMapS" />
                            {res.SALVAR}
                        </Button>
                    </div>
                </div>
                <div className="form-group" style={{ padding: '0 1rem', margin: '0' }}>
                    <FormControl className="buttonItemContainer" disabled>
                        <InputLabel htmlFor="type" className="titleItem">{res.SELECIONAR_ITEM}</InputLabel>
                        <div className="buttonItemBody">
                            <Button
                                style={{ margin: '0.25rem' }}
                                variant="contained"
                                className={aircraftItem === 'asaEsquerda' ? classes.primary : classes.secondary}
                                value="asaEsquerda"
                                onClick={() => this.handleChangeItem('asaEsquerda')}
                            >
                                {res.ASA_ESQUERDA}
                            </Button>
                            <Button
                                style={{ margin: '0.25rem' }}
                                variant="contained"
                                className={aircraftItem === 'barrigaEsquerda' ? classes.primary : classes.secondary}
                                value="barrigaEsquerda"
                                onClick={() => this.handleChangeItem('barrigaEsquerda')}
                            >
                                {res.BARRIGA_ESQUERDA}
                            </Button>
                            <Button
                                style={{ margin: '0.25rem' }}
                                variant="contained"
                                className={aircraftItem === 'barrigaDireita' ? classes.primary : classes.secondary}
                                value="barrigaDireita"
                                onClick={() => this.handleChangeItem('barrigaDireita')}
                            >
                                {res.BARRIGA_DIREITA}
                            </Button>
                            <Button
                                style={{ margin: '0.25rem' }}
                                variant="contained"
                                className={aircraftItem === 'asaDireita' ? classes.primary : classes.secondary}
                                value="asaDireita"
                                onClick={() => this.handleChangeItem('asaDireita')}
                            >
                                {res.ASA_DIREITA}
                            </Button>
                        </div>
                    </FormControl>
                    {typeEquipment === 'Bico Hidráulico'
                        ?
                        <div>
                            <div style={{ margin: '1rem 0' }}>
                                <InputLabel htmlFor="type" className="titleItem">{res.QUANTIDADE_TOTAL_BICOS}</InputLabel>
                                <h2>
                                    {Object.values(selectedPositionsNozzle)
                                        .reduce((total, positions) => total + positions.length, 0)} / {quantityNozzleRegistered}
                                </h2>
                            </div>
                            <div className="containerTypeEquipment">
                                {this.switchNozzleConfigurtation()}
                            </div>
                        </div>
                        :
                        <div>
                            <div style={{ margin: '2rem 0' }}>
                                <InputLabel htmlFor="type" className="titleItem">{res.QUANTIDADE_TOTAL_ATOMIZADORES}</InputLabel>
                                <h2>
                                    {this.calculateTotalSelectedPositions()} / {quantityAtomizerRegistered}
                                </h2>
                            </div>
                            <div className="containerTypeEquipment">
                                {this.switchAtomizerConfigurtation()}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

AircraftConfiguration.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(AircraftConfiguration);

const Box = (props) => {
    const { link, res } = props;
    let { id, id_equipment } = useParams();
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} id={id} id_equipment={id_equipment} res={res} />
            </div>
        </div>)
}

export default Box;

