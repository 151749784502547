import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';

import SvgIcon from '@material-ui/core/SvgIcon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme => ({
    tableContainer: {
        overflowY: 'auto',
        maxHeight: 'calc(100% - 2rem)',
    },
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
    },
    tableRow: {
        backgroundColor: theme.palette.background.paper,
        '&:nth-of-type(odd)': {
            backgroundColor: '#343a40',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#343a40',
        },
        '& > *': {
            padding: '0.5rem',
            borderBottom: '2px solid #ff8c00',
        },
    },
    tableCell: {
        textAlign: 'center',
        color: 'white',
    },
    tableHeadCell: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#fa7d34',
        backgroundColor: '#343a40',
        borderBottom: '1px solid #fa7d34',
        padding: '0.75rem',
    },
    cardmiddle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
});

class DiaryJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: props.jobs || [],
            isLoading: true,
            jobTypeMap: {
                budgeted: 'Orçamento',
                approved: 'Aprovado',
                running: 'Trabalhando',
                concluded: 'Concluído',
                canceled: 'Cancelado',
            },
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            this.update();
        }
    }

    update = () => {
        this.setState({
            jobs: this.props.jobs,
            isLoading: false,
        });
    };

    renderTable = () => {
        const { classes, res } = this.props;
        const { jobs, jobTypeMap } = this.state;

        return (
            <div className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{res.TITULO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.STATUS}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.AREA_PLANEJADA}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.AREA_EXECUTADA}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.ATUALIZADO_}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobs.map(job => (
                            <TableRow key={job.id} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>{job.title}</TableCell>
                                <TableCell className={classes.tableCell}>{jobTypeMap[job.type]}</TableCell>
                                <TableCell className={classes.tableCell}>{job.hplanned} ha</TableCell>
                                <TableCell className={classes.tableCell}>{job.hsprayed} ha</TableCell>
                                <TableCell className={classes.tableCell}>{Moment(job.updated).format("HH:mm")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    };

    render() {
        const { classes, res } = this.props;
        const { isLoading } = this.state;

        return (
            <div className='dashboard-card' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className='divContent' style={{ flexGrow: 1 }}>
                    <div className='cardheader'>
                        <SvgIcon viewBox="0 96 960 960" style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }}>
                            <path d="M140 936q-24 0-42-18t-18-42V505h60v371h452v60H140Zm121-120q-24 0-42.5-18T200 756V385h60v371h453v60H261Zm119-120q-24 0-42-18t-18-42V276q0-24 18-42t42-18h440q24 0 42 18t18 42v360q0 24-18 42t-42 18H380Zm0-60h440V338H380v298Z" />
                        </SvgIcon>
                        <span className='title'>
                            {res.TRABALHOS}
                        </span>
                    </div>
                    <div className={classes.cardmiddle}>
                        {isLoading ? (
                            <Skeleton variant="rect" width="100%" height={200} />
                        ) : (
                            this.renderTable()
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DiaryJob.propTypes = {
    classes: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired,
};

export default withStyles(styles)(DiaryJob);