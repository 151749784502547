import React from 'react';
import Config from '../../constants/appConfig';
import L, { popup } from 'leaflet';
import 'leaflet-draw';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowRightIcon from '@material-ui/icons/ChevronRight';
import SkipNextIcon from '@material-ui/icons/LastPage';

class DiaryMap extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  state = {
    polygons: [],
    hectaresSprayed: 0,
    hectaresTotal: 0,
    percentDone: 0,
    currentPolygonIndex: 0
  };

  componentDidMount() {
    this.initialize();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.update();
    }
  };

  initialize = () => {
    this.map = L.map('diaryMap', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);
    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map);
    L.control.scale({ imperial: false }).addTo(this.map);

    this.map.addEventListener("mouseover", (e) => {
      e.originalEvent.stopPropagation();
      this.props.ommouseovermap(true);
    });

    this.map.addEventListener("mouseout", () => {
      this.props.ommouseovermap(false);
    });

    this.featureGroupP = L.featureGroup().addTo(this.map);
    this.featureGroupM = L.featureGroup().addTo(this.map);
    this.featureGroupPoly = L.featureGroup().addTo(this.map);

    const resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });
    resizeObserver.observe(document.getElementById('diaryMap'));
  };

  update = () => {
    let numPolys = 0;
    let sprayedArea = 0.0;
    let areaTotal = 0.0;
    let polygons = [];

    this.props.jobs.forEach(job => {
      if (job.id_task) {
        const apiUrl = Config.server + '/getResult/' + job.id_task;
        const options = {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access-token-jwt')
          }
        }

        fetch(apiUrl, options)
          .then((res) => {
            if (res.status !== 200) throw res;
            else return res.json();
          })
          .then(
            (result) => {
              const j = JSON.parse(result);
              j.polygons.forEach(poly => {
                const area = L.GeometryUtil.geodesicArea(L.polygon(poly.poly.geometry.coordinates).getLatLngs()[0]);
                const areaHa = Math.round(area / 100) / 100;
                areaTotal += areaHa;

                this.polyMap(poly, j.title, areaHa);
                polygons.push(poly);
                numPolys++;
              });

              for (let i = 0; i < j.apl.aplicacao.length; i++) {
                const element = j.apl.aplicacao[i];
                this.aplMapCorrect(element);
                sprayedArea += element.area;
              }
            },
            (error) => {
              if (error.status === 401 || error.status === 403) {
                this.props.link('/login');
              }
              else {
                console.log(error);
              }
            }
          ).then(
            () => {
              if (numPolys > 0) {
                this.map.fitBounds(this.featureGroupPoly.getBounds());
              }

              this.setState({
                hectaresSprayed: sprayedArea,
                hectaresTotal: areaTotal,
                percentDone: (areaTotal > sprayedArea ? (sprayedArea / areaTotal) * 100 : 100),
                polygons: polygons,
                currentPolygonIndex: 0
              });
            }
          );
      }
    });
  };

  polyMap = (poly, jobTitle, area) => {
    const color_x = '#ff002b';
    let myStyle = {
      color: '#47839e',
      weight: 4,
      opacity: 0.5,
      fillColor: '#FF1493',
      fillOpacity: .7,
      className: 'area'
    };

    if (poly.type === 'X') {
      myStyle.color = color_x;
      myStyle.fillColor = color_x;
    }

    let myLayer = L.geoJSON(poly.poly, { style: myStyle });
    const areaText = area + ' ha';
    myLayer.bindTooltip(jobTitle + ' (' + areaText + ')', { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size10-L' });
    myLayer.addTo(this.featureGroupPoly);
  };

  aplMapCorrect = (poly) => {
    let myStyle = {
      color: '#32a852',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao'
    };

    const pop =
      '<b>' + this.props.res.TIRO + ': ' + poly.title + '</b><br> '
      + this.props.res.LARGURA_FAIXA + ': ' + poly.faixa + ' m<br> '
      + this.props.res.COMPRIMENTO + ': ' + poly.comprimento.toFixed(2) + ' m<br> '
      + this.props.res.AREA + ': ' + poly.area.toFixed(3) + ' ha<br> '
      + this.props.res.VOLUME + ': ' + poly.volume.toFixed(3) + ' L<br> '
      + this.props.res.FLUXO_MEDIO + ': ' + poly.fMedio.toFixed(3) + ' L/ha';

    let myLayer = L.geoJSON(poly.poly, { style: myStyle });
    myLayer.on("click", () => { this.handleClickAplMap(myLayer); });
    myLayer.bindPopup(pop);
    myLayer.addTo(this.featureGroupP);
  };

  handleClickAplMap = (layer) => {
    this.featureGroupP.eachLayer((item) => {
      item.setStyle({ color: '#32a852' });
    });
    if (layer) {
      layer.setStyle({ color: '#25c450' });
    }
  };

  navigatePolygons = (direction) => {
    let { currentPolygonIndex, polygons } = this.state;

    if (direction === 'next') {
      currentPolygonIndex = (currentPolygonIndex + 1) % polygons.length;
    } else if (direction === 'prev') {
      currentPolygonIndex = (currentPolygonIndex - 1 + polygons.length) % polygons.length;
    } else if (direction === 'end') {
      currentPolygonIndex = polygons.length - 1;
    }

    const selectedPolygon = polygons[currentPolygonIndex];
    if (selectedPolygon) {
      const bounds = L.geoJSON(selectedPolygon.poly).getBounds();
      this.map.fitBounds(bounds);
    }

    this.setState({ currentPolygonIndex });
  };

  render() {
    const { res } = this.props;

    return (
      <div className='dashboard-card'>
        <div className='divContent'>
          <div className='cardheader' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SettingsEthernetIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
              <span className='title'>{res.TALHOES}</span>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <ArrowLeftIcon style={{ cursor: 'pointer', color: '#fa7d34' }} onClick={() => this.navigatePolygons('prev')} />
              <ArrowRightIcon style={{ cursor: 'pointer', color: '#fa7d34' }} onClick={() => this.navigatePolygons('next')} />
              <SkipNextIcon style={{ cursor: 'pointer', color: '#fa7d34' }} onClick={() => this.navigatePolygons('end')} />
            </div>
          </div>
          <div className='cardmiddle' style={{ display: 'block', width: '100%', height: 'calc(100% - 85px)' }}>
            <div id="diaryMap" style={{ position: 'relative', width: '100%', height: 'calc(100%)', display: 'flex', border: 'none' }}></div>
          </div>
          <div className='cardfooter'>
            <span className='cardfooter-content' style={{ paddingTop: '0' }}>
              <span style={{ display: 'block', float: 'left', paddingLeft: '1rem', width: '50%', fontSize: '1rem', fontWeight: 'bold', paddingTop: '.8rem' }}>
                <span style={{ width: '100%', paddingTop: '.8rem' }}>
                  {this.state.hectaresSprayed.toFixed(2)}ha / {this.state.hectaresTotal.toFixed(2)}ha
                </span>
              </span>
              <span style={{ display: 'block', float: 'right', paddingRight: '1rem', width: '50%', paddingTop: '.2rem' }}>
                <span style={{ width: '100%', color: '#fa7d34' }}>
                  {res.INDICE_EXECUTADOS}
                </span>
                <span style={{ display: 'block', width: '100%', fontSize: '1rem', fontWeight: 'bold' }}>
                  <span style={{ display: 'inline-block' }}>
                    <span style={{ display: 'block', float: 'left', marginRight: '.2rem' }}>{this.state.percentDone.toFixed(0)}%</span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 20 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 40 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 60 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 80 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 100 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default DiaryMap;